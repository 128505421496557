import {
  NO_DATA_NUMBER,
  NOT_AVIABLE_LABEL,
} from "../constants/appBaseConstants";
import { LanguagesEnum } from "../types/appEnums";

export const formatNumber = (
  numberToFormat: number | null | undefined,
  language: LanguagesEnum
) => {
  return numberToFormat !== null && numberToFormat !== undefined
    ? checkNumber(numberToFormat).toLocaleString([language, LanguagesEnum.EN])
    : NO_DATA_NUMBER;
};

export const formatNumberWithRound = (
  numberToFormat: number | null | undefined,
  language: LanguagesEnum
) => {
  if (numberToFormat !== null && numberToFormat !== undefined) {
    return numberToFormat.toLocaleString(language, {
      minimumFractionDigits: 1,
    });
  }
  return NO_DATA_NUMBER;
};

export const checkNumber = (value: number) => {
  return value >= 0 ? value : 0;
};

export const roundNumberWithPrecision = (value: number, precision?: number) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const numberWithUnit = (value: string, unitType?: string) => {
  return value === NO_DATA_NUMBER ? value : `${value}${unitType}`;
};

export const formatNumberKpiServiceBrazilJapan = (
  numberToFormat: number | null | undefined,
  language: LanguagesEnum
) => {
  if (numberToFormat !== null && numberToFormat !== undefined) {
    return numberToFormat.toLocaleString(language, {
      minimumFractionDigits: 1,
    });
  }
  return NOT_AVIABLE_LABEL;
};
