import React from "react";
import { View, StyleSheet, FlatList } from "react-native";
import { Image } from "react-native-elements";
import { ScrollView } from "react-native-gesture-handler";
import useLayout from "../../hooks/useLayout";
import useScale, { Scales } from "../../hooks/useScale";

type ImageViewerProps = {
  imageIndex: number;
  images: string[];
};

export const ImageViewer = ({ imageIndex, images }: ImageViewerProps) => {
  const moderateScale = useScale();
  const { width } = useLayout();

  return (
    <View style={styles(width, moderateScale).modalContainer}>
      <ScrollView style={styles(width, moderateScale).scrollView}>
        <FlatList
          data={images}
          keyExtractor={(item) => item}
          horizontal
          initialScrollIndex={imageIndex}
          snapToInterval={width}
          getItemLayout={(item, index) => ({
            length: width,
            offset: width * index,
            index,
          })}
          pagingEnabled
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            <View style={styles(width, moderateScale).labImageContainer}>
              <Image
                style={styles(width, moderateScale).labImage}
                source={{ uri: item }}
              />
            </View>
          )}
        />
      </ScrollView>
    </View>
  );
};

const styles = (width: number, { moderateScale }: Scales) => {
  return StyleSheet.create({
    modalContainer: {
      zIndex: 10,
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(39,45,58, 0.8)",
      position: "absolute",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
    scrollView: {
      width: "100%",
      position: "absolute",
      flex: 1,
    },
    labImageContainer: {
      flex: 1,
      flexDirection: "column",
      margin: 1,
      width: width,
    },
    labImage: {
      width: "100%",
      height: moderateScale(210, 0.3),
    },
  });
};
