export enum TimeFiltersEnum {
  DAY = "DAY",
  WTD = "WTD",
  MTD = "MTD",
  QTD = "QTD",
  YTD = "YTD",
}

export enum BrzEnum {
  IT = "Br",
  EN = "Brz",
}

export enum PeriodsEnum {
  DAY = "DAY",
  WTD = "WTD",
  MTD = "MTD",
  QTD = "QTD",
  YTD = "YTD",
}

export enum LanguagesEnum {
  IT = "it",
  EN = "en",
  FR = "fr",
}

export type OptionalLanguages = { abbr?: string };

export enum SplitIdsEnum {
  LAB_GROUP = "LAB",
  CHANNEL_GROUP = "CHANNEL",
}

export enum ThemeEnum {
  DARK = "dark",
  LIGHT = "light",
}
