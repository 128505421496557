import { from, Observable } from "rxjs";

import { API_ENDPOINT, API_SETTINGS } from "./ApiBaseSettings";
import {
  LensesApiParams,
  OverviewAllParams,
  ReportAllParams,
  InfoLabAllParams,
} from "../types/apiParamsTypes";
import {
  LensesData,
  OverviewData,
  ReportData,
  InfoLabData,
} from "../types/apiTypes";

import { LOCAL_STORAGE_TOKEN_NAME } from "../constants/appBaseConstants";
import { switchMap } from "rxjs/operators";
import AsyncStorage from "@react-native-community/async-storage";
import { trackedAjaxGetJson } from "./AppInsigthsTracker";
import { AjaxResponse } from "rxjs/ajax";

export const getAllOverview = ({
  lab,
  time,
}: OverviewAllParams): Observable<AjaxResponse<OverviewData>> => {
  return from(AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)).pipe(
    switchMap(() => {
      return trackedAjaxGetJson({
        ...API_SETTINGS,
        url: `${API_ENDPOINT}overview/${lab}/${time}`,
      });
    })
  ) as Observable<OverviewData>;
};

export const getReport = ({
  lab,
}: ReportAllParams): Observable<AjaxResponse<ReportData>> => {
  return from(AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)).pipe(
    switchMap(() => {
      return trackedAjaxGetJson({
        ...API_SETTINGS,
        url: `${API_ENDPOINT}report/${lab}`,
      });
    })
  ) as Observable<ReportData>;
};

export const getLenses = ({
  lab,
  channel,
  time,
}: LensesApiParams): Observable<AjaxResponse<LensesData>> => {
  return from(AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)).pipe(
    switchMap(() => {
      return trackedAjaxGetJson({
        ...API_SETTINGS,
        url: `${API_ENDPOINT}lenses/${lab}/${channel}/${time}`,
      });
    })
  ) as Observable<LensesData>;
};

export const getInfoLab = ({
  lab,
}: InfoLabAllParams): Observable<AjaxResponse<InfoLabData>> => {
  return from(AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)).pipe(
    switchMap(() => {
      return trackedAjaxGetJson({
        ...API_SETTINGS,
        url: `${API_ENDPOINT}datalab/${lab}`,
      });
    })
  ) as Observable<InfoLabData>;
};
