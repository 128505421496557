import { NavigationContainer, DefaultTheme } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";

import NotFoundScreen from "../screens/NotFoundScreen";
import BottomTabNavigator from "./BottomTabNavigator";
import { useContext } from "react";
import { ThemeContext } from "../context/ThemeContext";
import LensesScreen from "../screens/LensesScreen";
import { RootStackParamList } from "../types/navigationTypes";
import {
  ROOT,
  LENSES_CHANNEL_STACK,
  LENSES,
  NOT_FOUND,
  headerSettings,
  AGGREGATE_LIST_STACK,
  INFO_PAGE_STACK,
} from "../constants/navigationConstants";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useScale from "../hooks/useScale";
import PerfLensesBottomTabNavigator from "./PerfLensesBottomTabNavigator";
import InfoLabTabNavigator from "./InfoLabTabNavigator";

export default function Navigation() {
  const { darkMode, colors } = useContext(ThemeContext);
  return (
    <NavigationContainer
      documentTitle={{ enabled: false }}
      theme={{
        dark: darkMode,
        colors: {
          ...DefaultTheme.colors,
          card: colors.background,
          background: colors.background,
          border: colors.background,
          text: colors.text,
        },
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createStackNavigator<RootStackParamList>();

function RootNavigator() {
  const { bottom } = useSafeAreaInsets();
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name={ROOT} component={BottomTabNavigator} />
      <Stack.Screen
        name={LENSES_CHANNEL_STACK}
        component={LensesScreen}
        options={{
          ...headerSettings(colors, scaleFunctions, bottom),
          headerShown: true,
          title: LENSES,
        }}
      />
      <Stack.Screen
        name={AGGREGATE_LIST_STACK}
        component={PerfLensesBottomTabNavigator}
      />
      <Stack.Screen name={INFO_PAGE_STACK} component={InfoLabTabNavigator} />
      <Stack.Screen
        name={NOT_FOUND}
        component={NotFoundScreen}
        options={{ title: "Oops!" }}
      />
    </Stack.Navigator>
  );
}
