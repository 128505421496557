import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="18" height="18" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <Path
              d="M0 0L18 0 18 18 0 18z"
              transform="translate(-292 -699) translate(0 690) translate(291 8)"
            />
            <Path
              fill={color}
              fill-rule="nonzero"
              d="M14.572 9.735c.03-.24.053-.48.053-.735s-.023-.495-.053-.735l1.583-1.238c.142-.112.18-.314.09-.48l-1.5-2.594c-.067-.12-.195-.188-.33-.188-.045 0-.09.007-.127.022l-1.868.75c-.39-.3-.81-.547-1.268-.734l-.284-1.988c-.023-.18-.18-.315-.368-.315h-3c-.188 0-.345.135-.367.315l-.285 1.988c-.458.187-.878.442-1.268.734l-1.868-.75c-.045-.014-.09-.022-.135-.022-.127 0-.255.067-.322.188l-1.5 2.595c-.097.165-.052.367.09.48l1.583 1.237c-.03.24-.053.487-.053.735s.022.495.053.735l-1.583 1.238c-.143.112-.18.314-.09.48l1.5 2.595c.067.12.195.187.33.187.045 0 .09-.008.127-.022l1.868-.75c.39.3.81.547 1.268.735l.285 1.987c.022.18.18.315.367.315h3c.188 0 .345-.135.367-.315l.285-1.987c.458-.188.878-.443 1.268-.736l1.867.75c.045.015.09.023.135.023.128 0 .255-.067.323-.188l1.5-2.595c.09-.165.052-.367-.09-.48l-1.583-1.237zm-1.484-1.282c.03.232.037.39.037.547 0 .158-.015.322-.037.547l-.105.848.667.525.81.63-.525.907-.953-.382-.78-.315-.675.51c-.322.24-.63.42-.937.548l-.795.322-.12.848L9.525 15h-1.05l-.143-1.012-.12-.848-.794-.322c-.323-.136-.623-.308-.923-.533l-.683-.525-.795.322-.952.383-.525-.908.81-.63.668-.524-.106-.848C4.89 9.323 4.875 9.15 4.875 9c0-.15.015-.322.037-.547l.106-.848-.668-.525-.81-.63.525-.907.952.382.78.315.676-.51c.322-.24.63-.42.937-.548l.795-.322.12-.848L8.475 3h1.043l.142 1.013.12.847.795.322c.323.135.623.308.923.533l.682.525.795-.322.953-.383.524.907-.802.638-.668.525.106.848zM9 6C7.343 6 6 7.343 6 9c0 1.658 1.343 3 3 3 1.658 0 3-1.342 3-3 0-1.657-1.342-3-3-3zm0 4.5c-.825 0-1.5-.675-1.5-1.5S8.175 7.5 9 7.5s1.5.675 1.5 1.5-.675 1.5-1.5 1.5z"
              transform="translate(-292 -699) translate(0 690) translate(291 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
