import * as React from "react";
import { BehaviorSubject } from "rxjs";
import { Filter, LabeledObject } from "../types/appTypes";
import { VLabNavigationState, VLabNavigationStore } from "../types/storesTypes";
import { ALL_LABELED_OBJECT } from "../constants/appBaseConstants";

const defaultState: VLabNavigationState = {
  labStack: [],
  selectedLab: ALL_LABELED_OBJECT,
  selectedChannel: ALL_LABELED_OBJECT,
  selectedLensesFilter: "ALL",
  lensesFilters: [],
  navigationStack: [],
};

const subject = new BehaviorSubject<VLabNavigationState>(defaultState);

let state = defaultState;

const navigationStore: VLabNavigationStore = {
  subscribe: (setState: React.Dispatch<VLabNavigationState>) =>
    subject.subscribe(setState),
  pushLab: (lab: LabeledObject) => {
    state = { ...state, labStack: [...state.labStack, lab] };
    subject.next({ ...state });
  },
  popLab: () => {
    const labToRemove = state.labStack[state.labStack.length - 1];
    state = {
      ...state,
      labStack: [...state.labStack.slice(0, state.labStack.length - 1)],
    };
    state = { ...state, selectedLab: labToRemove };
    subject.next({ ...state });
  },
  changeSelectedLab: (lab: LabeledObject) => {
    state = { ...state, selectedLab: lab };
    subject.next({ ...state });
  },
  changeSelectedChannel: (channel: LabeledObject) => {
    state = { ...state, selectedChannel: channel };
    subject.next({ ...state });
  },
  changeSelectedLensesFilter: (filter: string) => {
    state = { ...state, selectedLensesFilter: filter };
    subject.next({ ...state });
  },
  changeLensesFilters: (filters: Filter[]) => {
    state = { ...state, lensesFilters: filters };
    subject.next({ ...state });
  },
  getPreviousLab: () => {
    return state.labStack[state.labStack.length - 1];
  },
  pushNavigationStack: (itemStack: string) => {
    state = {
      ...state,
      navigationStack: [...state.navigationStack, itemStack],
    };
    subject.next({ ...state });
  },
  popNavigationStack: () => {
    state = {
      ...state,
      navigationStack: [
        ...state.navigationStack.slice(0, state.navigationStack.length - 1),
      ],
    };
    subject.next({ ...state });
  },
  default: state,
};

export default navigationStore;
