import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="12" height="19">
    <Path
      fill="none"
      fillRule="nonzero"
      stroke={color}
      strokeLinecap="round"
      d="M11.5.5L1 9.3a.3.3 0 0 0 0 .4l10.5 8.7"
    />
  </Svg>
);
