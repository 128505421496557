import { ReportItem } from "../types/apiTypes";
import {
  DAY_NAME,
  MONTH_NAME,
  TIME_LONG_LABELS,
  TIME_SHORT_LABELS,
} from "./../constants/dictionary";
import { LanguagesEnum, TimeFiltersEnum } from "./../types/appEnums";

const REGEX_QUARTER = /[0-9]{4}-[Qq][0-9]{1,2}$/;
const REGEX_WEEK = /[0-9]{4}-[Ww][0-9]{1,2}$/;
const REGEX_YEAR = /[0-9]{4}$/;
const REGEX_DAY = /[0-9]{4}-[0-9]{1,2}-[0-9]{1,2}$/;
const REGEX_MONTH = /[0-9]{4}-[0-9]{1,2}$/;

export const formatYear = (selectedLanguage: LanguagesEnum) =>
  TIME_SHORT_LABELS[TimeFiltersEnum.YTD][selectedLanguage];
export const formatQuarter = (time: string) => time.split("-")[1];
export const formatWeek = (time: string, selectedLanguage: LanguagesEnum) =>
  TIME_LONG_LABELS[TimeFiltersEnum.WTD][selectedLanguage][0].toUpperCase() +
  TIME_LONG_LABELS[TimeFiltersEnum.WTD][selectedLanguage]
    .slice(1)
    .toLowerCase() +
  " " +
  time.split("-")[1].slice(1);
export const formatDay = (time: string, selectedLanguage: LanguagesEnum) =>
  DAY_NAME[new Date(time).getDay()][selectedLanguage] +
  " " +
  time.split("-")[2];
export const formatMonth = (time: string, selectedLanguage: LanguagesEnum) =>
  MONTH_NAME[parseInt(time.split("-")[1], 10) - 1][
    selectedLanguage
  ].toUpperCase();

export const getReportTable = (
  tableData: ReportItem[],
  selectedLanguage: LanguagesEnum
) => {
  const firstTable: ReportItem[] = [];
  const secondTable: ReportItem[] = [];
  const quarters: ReportItem[] = [];
  const weeks: ReportItem[] = [];
  const days: ReportItem[] = [];

  tableData.forEach((item: ReportItem) => {
    if (REGEX_QUARTER.test(item.id)) {
      quarters.push({ ...item, id: formatQuarter(item.id) });
    } else if (REGEX_YEAR.test(item.id)) {
      firstTable.push({ ...item, id: formatYear(selectedLanguage) });
    } else if (REGEX_DAY.test(item.id)) {
      days.push({ ...item, id: "\t\t" + formatDay(item.id, selectedLanguage) });
    } else if (REGEX_WEEK.test(item.id)) {
      weeks.push({ ...item, id: formatWeek(item.id, selectedLanguage) });
    } else if (REGEX_MONTH.test(item.id)) {
      secondTable.push({ ...item, id: formatMonth(item.id, selectedLanguage) });
    }
  });

  firstTable.unshift(...quarters);
  secondTable.push(...weeks);
  secondTable.push(...days);

  return {
    firstTable,
    secondTable,
  };
};
