import {
  StackScreenProps,
  StackHeaderTitleProps,
} from "@react-navigation/stack";
import React, { useState } from "react";
import { View } from "react-native";
import useBackToPreviousLab from "../hooks/useBackToPreviousLab";
import appStore from "../stores/appStore";
import { InfoParamList } from "../types/navigationTypes";
import LoadingView from "../components/LoadingView";
import mainService from "../services/mainService";
import { GlobalStatesContext } from "../context/GlobalStatesContext";
import { InfoLabData } from "../types/apiTypes";
import HeaderTitleComponent from "../components/HeaderTitleComponent";
import { getInfoLabTitle } from "../utils/headerUtils";
import { StyleSheet } from "react-native";
import { VLabColors } from "../types/appTypes";
import { ThemeContext } from "../context/ThemeContext";
import { LabInfo } from "../components/infoLab/LabInfo";
import { LabImage } from "../components/infoLab/LabImage";
import { ImageContainer } from "../components/infoLab/ImageContainer";

type InfoScreenProps = StackScreenProps<InfoParamList, "InfoScreen">;

const InfoScreen: React.FC<InfoScreenProps> = ({
  navigation,
}: InfoScreenProps) => {
  const { colors } = React.useContext(ThemeContext);
  const { appState, navigationState } = React.useContext(GlobalStatesContext);
  const backComponent = useBackToPreviousLab();
  const [isLoading, setIsLoading] = useState(false);
  const [infoLabData, setInfoLabData] = useState<InfoLabData>();
  const InfoLabTitle = React.useRef({ title: "" });
  const [showImageGallery, setShowImageGallery] = useState(false);

  React.useEffect(() => {
    if (infoLabData) {
      InfoLabTitle.current = getInfoLabTitle(
        navigationState.selectedLab,
        appState.selectedLanguage,
        appState.isChannelGroup
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.isChannelGroup, appState.selectedLanguage, infoLabData]);

  navigation.setOptions({
    headerTitle: (props: StackHeaderTitleProps) => (
      <HeaderTitleComponent {...props} title={InfoLabTitle.current.title} />
    ),
    headerLeft: () => backComponent.current,
  });

  React.useEffect(() => {
    appStore.setLoadingGauge(30);
    setIsLoading(true);
    const infoApiSubscription = mainService
      .callInfoLab({
        lab: navigationState.selectedLab.id,
      })
      .subscribe((res) => {
        appStore.setLoadingGauge(100);
        setInfoLabData(res);
        setIsLoading(false);
      });
    return () => {
      infoApiSubscription.unsubscribe();
    };
  }, [navigationState.selectedLab.id]);

  const setImgIsPressed = (isOpen: boolean) => {
    setShowImageGallery(isOpen);
  };

  return (
    <>
      <View style={styles(colors).container}>
        {infoLabData &&
          (!showImageGallery ? (
            <>
              {infoLabData.images.length !== 0 && (
                <LabImage
                  images={infoLabData.images[0]}
                  setImgIsPressed={setImgIsPressed}
                  numberImgToShow={infoLabData.images.length - 1}
                />
              )}
              <LabInfo infoLabData={infoLabData} />
            </>
          ) : (
            <>
              {infoLabData.images.length !== 0 && (
                <ImageContainer
                  images={infoLabData.images}
                  setImgIsPressed={setImgIsPressed}
                />
              )}
            </>
          ))}
      </View>
      {isLoading && <LoadingView />}
    </>
  );
};

export default React.memo(InfoScreen);

const styles = (colors: VLabColors) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: colors.overviewTableRowBackground,
    },
  });
};
