import { from, Observable } from "rxjs";
import { ajax, AjaxResponse } from "rxjs/ajax";

import { API_ENDPOINT, API_SETTINGS } from "./ApiBaseSettings";
import { LOCAL_STORAGE_TOKEN_NAME } from "../constants/appBaseConstants";
import { Info, Preferences } from "../types/apiTypes";
import AsyncStorage from "@react-native-community/async-storage";
import { mergeMap } from "rxjs/operators";
import { trackedAjaxGetJson, trackedAjaxPostJson } from "./AppInsigthsTracker";

export const getNam = (): Observable<AjaxResponse> => {
  return ajax.get(`${API_ENDPOINT}login/nam`);
};

export const getInfo = (): Observable<AjaxResponse<Info>> => {
  return from(AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)).pipe(
    mergeMap(() => {
      return trackedAjaxGetJson({
        ...API_SETTINGS,
        url: `${API_ENDPOINT}info`,
      });
    })
  ) as Observable<Info>;
};

export const setPreferences = (
  preferences: Preferences
): Observable<AjaxResponse> => {
  return from(AsyncStorage.getItem(LOCAL_STORAGE_TOKEN_NAME)).pipe(
    mergeMap(() => {
      return trackedAjaxPostJson({
        ...API_SETTINGS,
        url: `${API_ENDPOINT}user/savePreferences`,
        method: "POST",
        body: preferences,
      });
    })
  ) as Observable<AjaxResponse>;
};

export const getUser = (): Observable<AjaxResponse> => {
  return trackedAjaxGetJson({
    ...API_SETTINGS,
    url: `${API_ENDPOINT}user`,
  });
};
