import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="16" height="10" viewBox="0 0 16 10">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <path
              d="M0 0L18 0 18 18 0 18z"
              transform="translate(-82 -703) translate(0 690) translate(81 9)"
            />
            <Path
              fill={color}
              fill-rule="nonzero"
              d="M12 4.5L13.717 6.218 10.057 9.877 7.058 6.878 1.5 12.443 2.558 13.5 7.058 9 10.057 12 14.783 7.282 16.5 9 16.5 4.5z"
              transform="translate(-82 -703) translate(0 690) translate(81 9)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
