import {
  FE_INFO_TITLE,
  VERSION,
  BE_INFO_TITLE,
  DATA_VERSION,
  LAUNCHDATE,
  CUTDATE,
  WARNINGS,
} from "../constants/dictionary";
import { Info } from "../types/apiTypes";
import { LanguagesEnum } from "../types/appEnums";
import { tCard } from "../types/appTypes";
import { formatDate } from "../utils/formatDate";

export const cardData = (
  infoApi: Info | undefined,
  selectedLanguage: LanguagesEnum,
  version: string | undefined
) => {
  const sysUpdDate = new Date(infoApi?.launchDate as string);
  const cutDate = new Date(infoApi?.cutDate as string);

  const arr: Array<tCard> = [
    {
      head: {
        title: FE_INFO_TITLE[selectedLanguage],
        icon: "perm-device-information",
        type: "material",
      },
      items: [
        {
          title: `${VERSION[selectedLanguage]}`,
          value: `${version}`,
        },
      ],
    },
    {
      head: {
        title: BE_INFO_TITLE[selectedLanguage],
        icon: "update",
        type: "material",
      },
      items: [
        {
          title: `${DATA_VERSION[selectedLanguage]}`,
          value: `${infoApi?.version}`,
        },
        {
          title: `${LAUNCHDATE[selectedLanguage]}`,
          value: `${formatDate(sysUpdDate, selectedLanguage)}`,
        },
        {
          title: `${CUTDATE[selectedLanguage]}`,
          value: `${formatDate(cutDate, selectedLanguage, false)}`,
        },
      ],
    },
  ];

  if (infoApi && infoApi.warnings?.length > 0) {
    arr.push({
      head: {
        title: `${WARNINGS[selectedLanguage]}`,
        icon: "warning",
        type: "material",
      },
      items: infoApi?.warnings.map((w) => {
        return { title: w };
      }),
    });
  }

  return arr;
};
