import * as React from "react";
import { View } from "react-native";
import useScale from "../hooks/useScale";
import BackButtonSvg from "../components/svgs/BackButtonSvg";

const HeaderBackIcon = ({ tintColor }: { tintColor: string }) => {
  const { moderateScale } = useScale();
  return (
    <View
      style={{
        paddingRight: moderateScale(8, 0.2),
      }}
    >
      <BackButtonSvg color={tintColor} />
    </View>
  );
};

export default HeaderBackIcon;
