import useLayout from "./useLayout";

export type ScaleWithFactor = (size: number, factor?: number) => number;
export type Scale = (size: number) => number;
export type Scales = {
  scale: Scale;
  verticalScale: Scale;
  moderateScale: ScaleWithFactor;
  moderateVerticalScale: ScaleWithFactor;
  s: Scale;
  vs: Scale;
  ms: ScaleWithFactor;
  mvs: ScaleWithFactor;
};
export type ScaleFunctionArgs = [size: number, factor?: number];

const useScale = (): Scales => {
  const { width, height } = useLayout();
  const [shortDimension, longDimension] =
    width < height ? [width, height] : [height, width];
  const guidelineBaseWidth = 350;
  const guidelineBaseHeight = 680;
  const scale = (size: number) => (shortDimension / guidelineBaseWidth) * size;
  const verticalScale = (size: number) =>
    (longDimension / guidelineBaseHeight) * size;
  const moderateScale = (size: number, factor = 0.5) =>
    size + (scale(size) - size) * factor;
  const moderateVerticalScale = (size: number, factor = 0.5) =>
    size + (verticalScale(size) - size) * factor;
  const s = scale;
  const vs = verticalScale;
  const ms = moderateScale;
  const mvs = moderateVerticalScale;
  return {
    scale,
    verticalScale,
    moderateScale,
    moderateVerticalScale,
    s,
    vs,
    ms,
    mvs,
  };
};

export default useScale;
