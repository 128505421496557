import { StackScreenProps } from "@react-navigation/stack";
import React, { useContext } from "react";
import HeaderTitleComponent from "../components/HeaderTitleComponent";
import mainService from "../services/mainService";
import appStore from "../stores/appStore";
import { ReportData, ReportItem } from "../types/apiTypes";
import { ReportParamList } from "../types/navigationTypes";
import { Filter } from "../types/appTypes";
import { View } from "../components/Themed";
import FilterComponent from "../components/FilterComponent";
import { formatDate } from "../utils/formatDate";
import { GlobalStatesContext } from "../context/GlobalStatesContext";
import ReportTable from "../components/report/ReportTable";
import LoadingView from "../components/LoadingView";
import ReportLabBar from "../components/ReportLabBar";
import { REPORT_SCREEN_LABEL } from "../constants/dictionary";
import BarChart from "../components/BarChart/BarChart";
import useScale from "../hooks/useScale";
import { NUMBER_OF_QUARTER } from "../constants/appBaseConstants";
import { AxisData } from "../types/apiTypes";

type ReportScreenProps = StackScreenProps<ReportParamList, "ReportScreen">;

const ReportScreen: React.FC<ReportScreenProps> = ({
  navigation,
}: ReportScreenProps) => {
  const { appState } = useContext(GlobalStatesContext);
  const [reportData, setReportData] = React.useState<ReportData>();
  // used to store selected top filter
  const [labIdMemo, setLabIdMemo] = React.useState<string>("ALL");
  // used to make api calls
  const [labId, setLabId] = React.useState<string | null>("ALL");
  const [labGroupId, setLabGroupId] = React.useState<string | null>(null);
  // filters store
  const [topFilters, setTopFilters] = React.useState<Filter[]>([]);
  const [bottomFilters, setBottomFilters] = React.useState<Filter[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const setSelectedLab = (newLab: string) => {
    setLabGroupId(null);
    setLabId(newLab);
    setLabIdMemo(newLab);
  };

  const setSelectedLabGroup = (newLabGroup: string) => {
    setLabId(null);
    setLabGroupId(newLabGroup);
  };

  const { moderateScale } = useScale();

  React.useLayoutEffect(() => {
    if (reportData) {
      navigation.setOptions({
        headerTitle: (props) => (
          <HeaderTitleComponent
            {...props}
            title={appState && REPORT_SCREEN_LABEL[appState.selectedLanguage]}
            subtitle={formatDate(
              new Date(reportData?.time),
              appState.selectedLanguage,
              false
            )
              .split("/")
              .join(".")}
          />
        ),
      });
    }
  }, [appState, appState.selectedLanguage, labIdMemo, navigation, reportData]);

  React.useEffect(() => {
    if (labId) {
      appStore.setLoadingGauge(30);
      setIsLoading(true);
      const reportApiSubscription = mainService
        .callReport({ lab: labId })
        .subscribe((res) => {
          appStore.setLoadingGauge(100);
          setTopFilters(res.topNavBar);
          setBottomFilters(res.bottomNavBar);
          setReportData(res);
          setIsLoading(false);
        });
      return () => {
        reportApiSubscription.unsubscribe();
      };
    }
  }, [labId]);

  React.useEffect(() => {
    if (labGroupId) {
      appStore.setLoadingGauge(30);
      setIsLoading(true);
      const reportApiSubscription = mainService
        .callReport({ lab: labGroupId })
        .subscribe((res) => {
          appStore.setLoadingGauge(100);
          setReportData(res);
          setIsLoading(false);
        });
      return () => {
        reportApiSubscription.unsubscribe();
      };
    }
  }, [labGroupId]);

  const REGEX_QUARTER = /[0-9]{4}-[Qq][0-9]{1,2}$/;

  const reportQuarterFiltered = (tableReport: ReportItem[]) => {
    return tableReport.filter(function (item) {
      return REGEX_QUARTER.test(item.id);
    });
  };

  const formatQuarter = (time: string) => time.split("-")[1];

  const numberOfLabels = (dataLength: number): number => {
    return dataLength > NUMBER_OF_QUARTER ? dataLength : NUMBER_OF_QUARTER;
  };

  const getGraphData = (items: ReportItem[]) => {
    const graphData: {
      labels: string[];
      orders: Array<AxisData>;
      shipments: Array<AxisData>;
    } = { labels: [], orders: [], shipments: [] };
    items.map((item: ReportItem) => {
      const label = formatQuarter(item.id);
      graphData.labels.push(label);
      graphData.orders.push({
        x: label,
        y: item.orders || 0,
      });
      graphData.shipments.push({
        x: label,
        y: item.shipments || 0,
      });
    });
    return graphData;
  };

  const renderGraph = () => {
    const filteredData = reportData && reportQuarterFiltered(reportData.table);
    const graphData = filteredData && getGraphData(filteredData);
    const hasData = filteredData && filteredData.length > 0;

    if (hasData && graphData && reportData && reportData.graphConfig) {
      return (
        <View style={{ height: moderateScale(210, 0.3) }}>
          <BarChart
            numberOfLabels={numberOfLabels(graphData.labels.length)}
            blueBarData={graphData.orders}
            greyBarData={graphData.shipments}
            domainValue={reportData.graphConfig.domain}
            stepValue={reportData.graphConfig.step}
            language={appState.selectedLanguage}
          />
        </View>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <>
      <View style={{ flex: 1 }}>
        <FilterComponent
          filters={topFilters}
          selectedFilter={labIdMemo}
          changeFilter={setSelectedLab}
          language={"abbr"}
          selectedLanguage={appState.selectedLanguage}
          borderRadius={10}
          marginLateral={16}
        />

        <FilterComponent
          filters={bottomFilters}
          selectedFilter={labGroupId || labIdMemo}
          changeFilter={setSelectedLabGroup}
          language={appState.selectedLanguage}
          borderRadius={10}
          marginLateral={16}
        />

        {reportData && (
          <ReportLabBar
            selectedLanguage={appState.selectedLanguage}
            topNavBar={reportData.topNavBar}
            selectedFilter={labIdMemo}
          />
        )}

        {renderGraph()}

        {reportData && (
          <ReportTable
            tableData={reportData.table}
            selectedLanguage={appState.selectedLanguage}
            service={reportData.service}
          />
        )}
      </View>
      {isLoading && <LoadingView />}
    </>
  );
};

export default React.memo(ReportScreen);
