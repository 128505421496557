import { /*getMainApi,*/ getReport } from "./../apis/MainApi";
import { ReportAllParams } from "./../types/apiParamsTypes";
import {
  LensesApiParams,
  OverviewAllParams,
  InfoLabAllParams,
} from "../types/apiParamsTypes";
import { catchError, map } from "rxjs/operators";
import { getLenses, getAllOverview, getInfoLab } from "../apis/MainApi";
import handleError from "../utils/handleError";

class MainService {
  callAllOverview(overviewParams: OverviewAllParams) {
    return getAllOverview(overviewParams).pipe(
      map((res) => {
        return {
          ...res.response,
        };
      }),
      catchError((err) => {
        handleError(err);
        return [];
      })
    );
  }

  callReport(reportParams: ReportAllParams) {
    return getReport(reportParams).pipe(
      map((res) => {
        res.response.table.sort((a, b) => (a.id > b.id ? 1 : -1));
        return res.response;
      }),
      catchError((err) => {
        handleError(err);
        return [];
      })
    );
  }

  callLenses(lensesParams: LensesApiParams) {
    return getLenses(lensesParams).pipe(
      map((res) => {
        console.log("res: ", res);
        return {
          ...res.response,
        };
      }),
      catchError((err) => {
        handleError(err);
        return [];
      })
    );
  }

  callInfoLab(lensesParams: InfoLabAllParams) {
    return getInfoLab(lensesParams).pipe(
      map((res) => {
        return {
          ...res.response,
        };
      }),
      catchError((err) => {
        handleError(err);
        return [];
      })
    );
  }
}

export default new MainService();
