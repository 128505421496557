import React from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "./Themed";
import { LanguagesEnum } from "../types/appEnums";
import useScale, { Scales } from "../hooks/useScale";
import { ThemeContext } from "../context/ThemeContext";
import { Filter, VLabColors } from "../types/appTypes";
import { MEDIUM_FONT, TIME_BAR_FONT_SIZE } from "../constants/mainStyles";

type ReportLabBarProps = {
  selectedLanguage: LanguagesEnum;
  topNavBar: Filter[];
  selectedFilter: string | null;
};
const ReportLabBar = ({
  selectedLanguage,
  topNavBar,
  selectedFilter,
}: ReportLabBarProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  const filterLaboratoriesName = () => {
    const label = topNavBar.find((item) => item.id === selectedFilter);
    return label?.labels[selectedLanguage];
  };

  return (
    <View
      style={ReportLabBarStyle(colors, scaleFunctions).ReportLabBarContainer}
    >
      <Text style={ReportLabBarStyle(colors, scaleFunctions).ReportLabBarText}>
        {`${filterLaboratoriesName()}`}
      </Text>
    </View>
  );
};

const ReportLabBarStyle = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    ReportLabBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: moderateScale(15, 0.2),
      paddingRight: moderateScale(15, 0.2),
      backgroundColor: colors.timeBarBackground,
      height: moderateScale(23, 0.2),
    },
    ReportLabBarText: {
      flex: 4,
      color: colors.timebarText,
      fontFamily: MEDIUM_FONT,
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
      fontWeight: "bold",
    },
  });

export default ReportLabBar;
