import React, { useState } from "react";
import { ThemeContext } from "../context/ThemeContext";
import Colors from "../constants/Colors";
import { ThemeEnum } from "../types/appEnums";
import useMountEffect from "../hooks/useMountEffect";
import appStore from "../stores/appStore";

const ThemeProvider = (props: any) => {
  const [{ darkMode }, setAppState] = useState(appStore.default);

  useMountEffect(() => {
    const appStateSubscription = appStore.subscribe(setAppState);
    return () => {
      appStateSubscription.unsubscribe();
    };
  });

  const toggle = () => {
    const newThemeId = darkMode ? ThemeEnum.LIGHT : ThemeEnum.DARK;
    appStore.changeDarkMode(newThemeId);
  };

  return (
    <ThemeContext.Provider
      value={{
        darkMode,
        toggle,
        colors: Colors[darkMode ? ThemeEnum.DARK : ThemeEnum.LIGHT],
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
