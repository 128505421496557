import React from "react";
import { View, StyleSheet } from "react-native";
import { AppState } from "../types/storesTypes";
import { TIME_LONG_LABELS, FROM, TO } from "../constants/dictionary";
import { ThemeContext } from "../context/ThemeContext";
import { Text } from "./Themed";
import { Time } from "../types/apiTypes";
import { VLabColors } from "../types/appTypes";
import { formatDate } from "../utils/formatDate";
import DaysCounter from "./DaysCounter";
import { MEDIUM_FONT, TIME_BAR_FONT_SIZE } from "../constants/mainStyles";
import { TimeFiltersEnum } from "../types/appEnums";
import useScale, { Scales } from "../hooks/useScale";

type TimeBarProps = {
  appState: AppState;
  timeData?: Time;
  days?: number;
  timeSpanKey: TimeFiltersEnum;
};

const TimeBar = ({ appState, timeData, days, timeSpanKey }: TimeBarProps) => {
  const { selectedLanguage } = appState;
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  if (!timeData || !days) {
    return (
      <View style={timeBarStyle(colors, scaleFunctions).timeBarContainer} />
    );
  }

  const from = formatDate(new Date(timeData.gregFrom), selectedLanguage, false);
  const to = formatDate(new Date(timeData.gregTo), selectedLanguage, false);
  let timeSpanId;
  let periodsLabel;
  if (timeSpanKey === TimeFiltersEnum.DAY) {
    timeSpanId = from;
    periodsLabel = "";
  } else {
    const splittedId = timeData.id.split("-");
    timeSpanId = splittedId[splittedId.length - 1].replace(/W|Q/i, "");
    periodsLabel = `${FROM[selectedLanguage]} ${from} ${TO[selectedLanguage]} ${to}`;
  }
  const timeSpanLabel = `${TIME_LONG_LABELS[timeSpanKey][selectedLanguage]} ${timeSpanId}`;
  return (
    <View style={timeBarStyle(colors, scaleFunctions).timeBarContainer}>
      <Text style={timeBarStyle(colors, scaleFunctions).timeBarText}>
        {`${timeSpanLabel} ${periodsLabel}`}
      </Text>
      <DaysCounter days={days} />
    </View>
  );
};

const timeBarStyle = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    timeBarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: moderateScale(15, 0.2),
      paddingRight: moderateScale(15, 0.2),
      backgroundColor: colors.timeBarBackground,
      height: moderateScale(23, 0.2),
    },
    timeBarText: {
      flex: 4,
      color: colors.timebarText,
      fontFamily: MEDIUM_FONT,
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
      fontWeight: "bold",
    },
  });

export default TimeBar;
