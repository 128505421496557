import React from "react";
import { ButtonGroup } from "react-native-elements";
import { StyleSheet, StyleProp, ViewStyle } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import { Filter, Label, VLabColors } from "../types/appTypes";
import { BASE_FONT, TIME_BAR_FONT_SIZE } from "../constants/mainStyles";
import useScale, { Scales } from "../hooks/useScale";
import { View } from "./Themed";
import { BrzEnum, LanguagesEnum } from "../types/appEnums";
import { ALL } from "../constants/dictionary";

const filterComponentStyle = (
  colors: VLabColors,
  { moderateScale, moderateVerticalScale }: Scales
) =>
  StyleSheet.create({
    container: {
      maxHeight: moderateScale(20, 0.2),
      marginHorizontal: 0,
      marginBottom: moderateVerticalScale(10, 0.2),
      backgroundColor: colors.filterBackground,
    },
    text: {
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
      fontFamily: BASE_FONT,
    },
    selectedContainer: {
      backgroundColor: colors.timespanFilterClickedBackground,
    },
    selectedText: {
      color: "black",
    },
  });

type FilterComponentProps = {
  filters: Filter[];
  selectedFilter: string;
  changeFilter: (filterId: string) => void;
  language: keyof Label;
  selectedLanguage?: LanguagesEnum;
  containerStyle?: StyleProp<ViewStyle>;
  containerBackgroundColor?: string;
  selectedContainerBackgroundColor?: string;
  innerBorderColor?: string;
  textColor?: string;
  borderRadius?: number;
  marginLateral?: number;
};

export default ({
  filters,
  selectedFilter,
  changeFilter,
  language,
  selectedLanguage = LanguagesEnum.EN,
  containerStyle,
  containerBackgroundColor,
  selectedContainerBackgroundColor,
  innerBorderColor,
  textColor,
  borderRadius,
  marginLateral,
}: FilterComponentProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  if (filters.length === 0) {
    return <View />;
  }

  return (
    <ButtonGroup
      onPress={(index: number) => {
        changeFilter(filters[index].id);
      }}
      selectedIndex={filters.findIndex(
        (filter: Filter) => filter.id === selectedFilter
      )}
      buttons={filters.map((filter: Filter) => {
        if (
          filter.labels.abbr &&
          filter.labels.abbr === BrzEnum.EN &&
          selectedLanguage === LanguagesEnum.IT
        ) {
          return BrzEnum.IT;
        }

        if (filter.labels.abbr && filter.labels.abbr === ALL.en) {
          if (selectedLanguage === LanguagesEnum.EN) return ALL.en;
          if (selectedLanguage === LanguagesEnum.IT) return ALL.it;
          if (selectedLanguage === LanguagesEnum.FR) return ALL.fr;
        }

        return filter.labels[language] || "";
      })}
      containerStyle={[
        filterComponentStyle(colors, scaleFunctions).container,
        {
          borderWidth: 0,
          borderRadius: borderRadius ? borderRadius : 0,
          marginHorizontal: marginLateral ? marginLateral : 0,
        },
        containerStyle,
        containerBackgroundColor
          ? {
              backgroundColor: containerBackgroundColor,
            }
          : {},
      ]}
      innerBorderStyle={{
        color: innerBorderColor ? innerBorderColor : colors.background,
        width: 2,
      }}
      textStyle={[
        filterComponentStyle(colors, scaleFunctions).text,
        { color: textColor ? textColor : colors.filterText },
      ]}
      selectedTextStyle={
        filterComponentStyle(colors, scaleFunctions).selectedText
      }
      selectedButtonStyle={[
        filterComponentStyle(colors, scaleFunctions).selectedContainer,
        selectedContainerBackgroundColor
          ? { backgroundColor: selectedContainerBackgroundColor }
          : {},
      ]}
    />
  );
};
