import React, { useContext } from "react";
import { View, StyleSheet, StyleProp, TextStyle } from "react-native";
import { Avatar } from "react-native-elements";
import { ThemeContext } from "../../context/ThemeContext";
import useScale, { Scales } from "../../hooks/useScale";
import { OverviewMeta } from "../../types/apiTypes";
import { LanguagesEnum } from "../../types/appEnums";
import { VLabColors } from "../../types/appTypes";
import { Text } from "../Themed";
import { GlobalStatesContext } from "../../context/GlobalStatesContext";

type CellWithImageProps = {
  itemMeta: OverviewMeta;
  selectedLanguage: LanguagesEnum;
  additionalTextStyle: StyleProp<TextStyle>;
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    images: { marginRight: moderateScale(5, 0.2) },
  });

const CellWithImage = ({
  itemMeta,
  selectedLanguage,
  additionalTextStyle,
}: CellWithImageProps) => {
  const { colors } = useContext(ThemeContext);
  const { navigationState } = React.useContext(GlobalStatesContext);
  const scale = useScale();
  const iconSize =
    itemMeta.id === "ALL" && navigationState.navigationStack.length === 0
      ? scale.moderateScale(45, 0.2)
      : scale.moderateScale(28, 0.2);

  const paddingLeftToCenterIcon =
    itemMeta.id !== "ALL" && navigationState.navigationStack.length === 0
      ? scale.moderateScale(8, 0.2)
      : 0;

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        paddingLeft: paddingLeftToCenterIcon,
      }}
    >
      <Avatar
        source={{ uri: itemMeta.url }}
        size={iconSize}
        rounded
        containerStyle={styles(colors, scale).images}
      />
      <Text
        numberOfLines={2}
        ellipsizeMode="clip"
        style={[additionalTextStyle]}
      >
        {itemMeta.labels[selectedLanguage]}
      </Text>
    </View>
  );
};

export default CellWithImage;
