import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="16" height="16" viewBox="0 0 16 16">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <Path
              d="M0 0L18 0 18 18 0 18z"
              transform="translate(-53 -700) translate(0 690) translate(52 9)"
            />
            <Path
              fill={color}
              fill-rule="nonzero"
              d="M8.25 5.25h1.5v1.5h-1.5v-1.5zm0 3h1.5v4.5h-1.5v-4.5zM9 1.5C4.86 1.5 1.5 4.86 1.5 9c0 4.14 3.36 7.5 7.5 7.5 4.14 0 7.5-3.36 7.5-7.5 0-4.14-3.36-7.5-7.5-7.5zM9 15c-3.308 0-6-2.693-6-6 0-3.308 2.692-6 6-6 3.307 0 6 2.692 6 6 0 3.307-2.693 6-6 6z"
              transform="translate(-53 -700) translate(0 690) translate(52 9)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
