import React from "react";
import { ActivityIndicator } from "react-native";
import { VIEW_STYLES } from "../constants/mainStyles";

const LoadingView = () => {
  return (
    <ActivityIndicator
      size={"large"}
      style={[
        VIEW_STYLES.centeredFlex,
        {
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(0,0,0, 0.4)",
        },
      ]}
    />
  );
};

export default LoadingView;
