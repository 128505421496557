import * as React from "react";
import { StyleSheet } from "react-native";
import { View } from "../components/Themed";
import { OverviewData, OverviewItem } from "../types/apiTypes";
import mainService from "../services/mainService";
import navigationStore from "../stores/navigationStore";
import { OverviewParamList } from "../types/navigationTypes";
import {
  StackScreenProps,
  StackHeaderTitleProps,
} from "@react-navigation/stack";
import { VIEW_STYLES } from "../constants/mainStyles";
import { getOverviewTitle } from "../utils/headerUtils";
import appStore from "../stores/appStore";
import FiltersAndTimeBar from "../components/FiltersAndTimeBar";
import { OverviewTable } from "../components/overview/OverviewTable";
import HeaderTitleComponent from "../components/HeaderTitleComponent";
import useScale from "../hooks/useScale";
import BarChart from "../components/BarChart/BarChart";
import { GlobalStatesContext } from "../context/GlobalStatesContext";
import { OVERVIEW_SCREEN_LABEL } from "../constants/dictionary";
import useBackToPreviousLab from "../hooks/useBackToPreviousLab";
import LoadingView from "../components/LoadingView";
import { AxisData } from "../types/apiTypes";
import { LanguagesEnum } from "../types/appEnums";
import { ThemeContext } from "../context/ThemeContext";
import { AGGREGATE_LAB } from "../constants/appBaseConstants";

type OverviewScreenProps = StackScreenProps<
  OverviewParamList,
  "OverviewScreen"
>;

const OverviewScreen: React.FC<OverviewScreenProps> = ({
  navigation,
}: OverviewScreenProps) => {
  const { appState, navigationState } = React.useContext(GlobalStatesContext);
  const [overviewData, setOverviewData] = React.useState<OverviewData>();
  const { moderateScale } = useScale();
  const backComponent = useBackToPreviousLab();
  const overviewTitle = React.useRef({ title: "", subtitle: "" });
  const [isLoading, setIsLoading] = React.useState(false);
  const { colors } = React.useContext(ThemeContext);

  navigation.setOptions({
    headerTitle: (props: StackHeaderTitleProps) => (
      <HeaderTitleComponent {...props} title={overviewTitle.current.title} />
    ),
    headerLeft: () => backComponent.current,
  });

  React.useEffect(() => {
    if (overviewData) {
      overviewTitle.current = getOverviewTitle(
        navigationState.selectedLab,
        appState.selectedLanguage,
        appState.isChannelGroup
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.isChannelGroup, appState.selectedLanguage, overviewData]);

  React.useEffect(() => {
    overviewTitle.current = {
      title: "",
      subtitle: OVERVIEW_SCREEN_LABEL[appState.selectedLanguage],
    };
    setOverviewData(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigationState.selectedLab]);

  React.useEffect(() => {
    appStore.setLoadingGauge(30);
    setIsLoading(true);
    const overviewApiSubscription = mainService
      .callAllOverview({
        lab: navigationState.selectedLab.id,
        time: appState.selectedTimeFilter,
      })
      .subscribe((res) => {
        appStore.setLoadingGauge(100);
        setOverviewData(res);
        setIsLoading(false);
        appStore.setIsChannelGroup(
          res.table.splitMeta.id?.toUpperCase().includes("CHANNEL")
        );
      });
    return () => {
      overviewApiSubscription.unsubscribe();
    };
  }, [appState.selectedTimeFilter, navigationState.selectedLab.id]);

  React.useEffect(() => {
    const resetLensesBottomFilters = () => {
      // whenever the lab is changed, bottom lenses filters must be reinitialized with default value
      // to avoid multiple calls in lenses and/or wrong filters
      navigationStore.changeSelectedLensesFilter(
        navigationState.selectedLab.id
      );
      navigationStore.changeLensesFilters([]);
    };

    resetLensesBottomFilters();
  }, [navigationState.selectedLab.id]);

  const getGraphData = (
    items: OverviewItem[],
    selectedLanguage: LanguagesEnum
  ) => {
    const graphData: {
      labels: string[];
      ordered: Array<AxisData>;
      shipped: Array<AxisData>;
    } = { labels: [], ordered: [], shipped: [] };

    items.map((item: OverviewItem) => {
      const label = item.meta.labels[selectedLanguage];
      graphData.labels.push(label);
      graphData.ordered.push({
        x: label,
        y: item.orders || 0,
      });
      graphData.shipped.push({
        x: label,
        y: item.shipments || 0,
      });
    });
    return graphData;
  };

  const renderGraph = (apiData: OverviewData) => {
    const orders = apiData.table.head.orders || 0;
    const shipments = apiData.table.head.shipments || 0;
    const isNaLaboratories =
      navigationState.navigationStack.length === 1 &&
      navigationState.navigationStack[
        navigationState.navigationStack.length - 1
      ] === AGGREGATE_LAB;
    const shouldShowGraph =
      apiData.table.splitMeta.id?.includes("CHANNEL") ||
      !apiData.table.splitMeta.id ||
      isNaLaboratories;
    const hasData =
      (orders > 0 || shipments > 0) && apiData.table.items.length > 0;

    const graphData = getGraphData(
      apiData.table.items,
      appState.selectedLanguage
    );

    if (shouldShowGraph && hasData && apiData.table.head.meta.graphConfig) {
      return (
        <View style={{ height: moderateScale(210, 0.3) }}>
          <BarChart
            numberOfLabels={graphData.labels.length}
            blueBarData={graphData.ordered}
            greyBarData={graphData.shipped}
            domainValue={apiData.table.head.meta.graphConfig.domain}
            stepValue={apiData.table.head.meta.graphConfig.step}
            language={appState.selectedLanguage}
          />
        </View>
      );
    } else {
      return <React.Fragment />;
    }
  };

  return (
    <>
      <View
        style={[
          styles.container,
          { borderBottomWidth: 2, borderBottomColor: colors.tableRowBgCol },
        ]}
      >
        <FiltersAndTimeBar apiData={overviewData} appState={appState} />
        <View style={VIEW_STYLES.centeredFlex}>
          {overviewData && (
            <View style={styles.container}>
              {renderGraph(overviewData)}
              <OverviewTable
                selectedLanguage={appState.selectedLanguage}
                overviewData={overviewData}
                navigationState={navigationState}
              />
            </View>
          )}
        </View>
      </View>
      {isLoading && <LoadingView />}
    </>
  );
};

export default React.memo(OverviewScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
});
