import * as React from "react";
import { StyleSheet, View } from "react-native";
import { Icon } from "react-native-elements";
import { ThemeContext } from "../context/ThemeContext";
import useScale from "../hooks/useScale";

const WEEK_DAYS = 7;

type DaysCounterProps = {
  days: number;
};

const DaysCounter = ({ days }: DaysCounterProps) => {
  const { colors } = React.useContext(ThemeContext);
  const { moderateScale } = useScale();
  return (
    <View style={styles.container}>
      {Array.from({ length: days }, (_, index) => {
        return (
          <Icon
            testID="filledDot"
            key={"filledDot" + index}
            name="controller-record"
            type="entypo"
            color={colors.text}
            size={moderateScale(10, 0.2)}
          />
        );
      })}
      {Array.from({ length: WEEK_DAYS - days }, (_, index) => {
        return (
          <Icon
            testID="emptyDot"
            key={"emptyDot" + index}
            name="controller-record"
            type="entypo"
            color={colors.dotsDays}
            size={moderateScale(10, 0.2)}
          />
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    maxWidth: 100,
  },
});

export default DaysCounter;
