import { DEFAULT_HEX_OPACITY } from "../constants/mainStyles";

// from https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
// non working: change the base color

// from https://www.sitepoint.com/javascript-generate-lighter-darker-color/
export const brightenUp = (color: string, lum?: number) => {
  // if the hex is 3 digit long, convert to 6 digit
  color = String(color).replace(/[^0-9a-f]/gi, "");
  if (color.length < 6) {
    color = color[0] + color[0] + color[1] + color[1] + color[2] + color[2];
  }
  lum = lum || DEFAULT_HEX_OPACITY;
  // convert to decimal and change luminosity
  let rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(color.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }
  return rgb;
};
/*

    bgColOdd = colors.tableRowBgColPair.slice(0, 1) +
      HEX_OPACITY[DEFAULT_HEX_OPACITY] +
      colors.tableRowBgColPair.slice(1)
*/
