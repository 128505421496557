import { TimeFiltersEnum } from "../types/appEnums";
import { Label } from "../types/appTypes";

export const TIME_SHORT_LABELS: {
  [key in TimeFiltersEnum]: Label;
} = {
  [TimeFiltersEnum.DAY]: {
    it: "DAY",
    en: "DAY",
    fr: "DAY",
  },
  [TimeFiltersEnum.WTD]: {
    it: "WTD",
    en: "WTD",
    fr: "WTD",
  },
  [TimeFiltersEnum.MTD]: {
    it: "MTD",
    en: "MTD",
    fr: "MTD",
  },
  [TimeFiltersEnum.QTD]: {
    it: "QTD",
    en: "QTD",
    fr: "QTD",
  },
  [TimeFiltersEnum.YTD]: {
    it: "YTD",
    en: "YTD",
    fr: "YTD",
  },
};

export const TIME_SHORT_LW: Label = {
  it: "LW",
  en: "LW",
  fr: "LW",
};

export const TIME_LONG_LABELS: {
  [key in TimeFiltersEnum]: Label;
} = {
  [TimeFiltersEnum.DAY]: {
    it: "Giorno",
    en: "Day",
    fr: "Jour",
  },
  [TimeFiltersEnum.WTD]: {
    it: "Settimana",
    en: "Week",
    fr: "Semaine",
  },
  [TimeFiltersEnum.MTD]: {
    it: "Mese",
    en: "Month",
    fr: "Mois",
  },
  [TimeFiltersEnum.QTD]: {
    it: "Trimestre",
    en: "Quarter",
    fr: "Trimestre",
  },
  [TimeFiltersEnum.YTD]: {
    it: "Anno",
    en: "Year",
    fr: "Année",
  },
};

export const MONTH_NAME: Label[] = [
  { it: "Gennaio", en: "January", fr: "Janvier" },
  { it: "Febbraio", en: "February", fr: "Février" },
  { it: "Marzo", en: "March", fr: "Mars" },
  { it: "Aprile", en: "April", fr: "Avril" },
  { it: "Maggio", en: "May", fr: "Mai" },
  { it: "Giugno", en: "June", fr: "Juin" },
  { it: "Luglio", en: "July", fr: "Juillet" },
  { it: "Agosto", en: "August", fr: "Août" },
  { it: "Settembre", en: "September", fr: "Septembre" },
  { it: "Ottobre", en: "October", fr: "Octobre" },
  { it: "Novembre", en: "November", fr: "Novembre" },
  { it: "Dicembre", en: "December", fr: "Décembre" },
];

export const DAY_NAME: Label[] = [
  { it: "Dom", en: "Sun", fr: "Dim" },
  { it: "Lun", en: "Mon", fr: "Lun" },
  { it: "Mar", en: "Tue", fr: "Mar" },
  { it: "Mer", en: "Wed", fr: "Mer" },
  { it: "Gio", en: "Thu", fr: "Jeu" },
  { it: "Ven", en: "Fri", fr: "Ven" },
  { it: "Sab", en: "Sat", fr: "Sam" },
];
/**
 * translations for SettingsScreen.tsx
 */

export const DARK_THEME: Label = {
  en: "Dark Theme",
  it: "Tema Scuro",
  fr: "Fond foncé",
};
export const SETTINGS_PAGE_TITLE: Label = {
  en: "Preferences",
  it: "Preferenze",
  fr: "Réglages",
};

export const REFRESH: Label = {
  en: "Refresh",
  it: "Aggiorna",
  fr: "Actualiser",
};

export const FE_INFO_TITLE: Label = {
  en: "Client app Information",
  it: "Info Applicazione Client",
  fr: "Infos Application Client",
};

export const BE_INFO_TITLE: Label = {
  en: "Server Information",
  it: "Info Server",
  fr: "Infos Serveur",
};

export const VERSION: Label = {
  en: "Client version",
  it: "Versione client",
  fr: "Version Client",
};

export const DATA_VERSION: Label = {
  en: "Data id",
  it: "Id dati",
  fr: "Donnés id",
};

export const LAUNCHDATE: Label = {
  en: "System updated",
  it: "Sistema aggiornato",
  fr: "Système mis à jour",
};

export const CUTDATE: Label = {
  en: "Last available date",
  it: "Ultima data disponibile",
  fr: "Dernière date disponible",
};

export const WARNINGS: Label = {
  en: "Data warnings",
  it: "Segnalazioni dati",
  fr: "Alerte des données",
};

export const ERROR_SUBTEXT: Label = {
  en: "Make sure you are connected to the internet and click the button below.",
  it:
    "Assicurati di essere connesso a Internet e fai clic sul pulsante in basso.",
  fr:
    "Assurez-vous d'être connecté à l'Internet et cliquez sur le bouton ci-dessous.",
};

export const NOT_FOUND_ERROR: Label = {
  en: "Page not found",
  it: "Pagina non trovata",
  fr: "Page non trouvée",
};

export const RELOAD: Label = {
  en: "Reload",
  it: "Ricarica",
  fr: "Recharger",
};

export const GENERIC_ERROR_MESSAGE: Label = {
  en: "Something went wrong",
  it: "Qualcosa è andato storto",
  fr: "Quelque chose a mal tourné.",
};

export const TABLE_HEADER_EMPTY: Label = { it: "", en: "", fr: "" };
export const TABLE_HEADER_ORDERED: Label = {
  it: "Ordinato",
  en: "Ordered",
  fr: "Commandé",
};
export const TABLE_HEADER_SHIPPED: Label = {
  it: "Spedito",
  en: "Shipped",
  fr: "Envoyé",
};
export const TABLE_HEADER_SERVICE: Label = {
  it: "Servizio",
  en: "Service",
  fr: "Service",
};
export const TABLE_HEADER_QUALITY: Label = {
  it: "Scarti",
  en: "Scrap",
  fr: "Rebuts",
};
export const TABLE_HEADER_DAYS: Label = {
  it: "Gg. Medi",
  en: "Avg. Days",
  fr: " Js. Moy.",
};
export const LANGUAGE: Label = {
  en: "Language",
  it: "Lingua",
  fr: "Langue",
};

export const PROFILE_CARD_TITLE: Label = {
  en: "Profile",
  it: "Profilo",
  fr: "Profil utilisateur",
};

export const FROM: Label = {
  en: "from",
  it: "dal",
  fr: "depuis",
};

export const TO: Label = {
  en: "to",
  it: "al",
  fr: "à",
};

export const BY: Label = {
  en: "By",
  it: "Per",
  fr: "de",
};

export const APP_INFO: Label = {
  en: "App Infos",
  it: "Informazioni sull'app",
  fr: "Informations sur l'appli",
};

export const LABORATORIES: Label = {
  en: "Laboratories",
  it: "Laboratori",
  fr: "Laboratoires",
};

export const ENGLISH: Label = { it: "Inglese", en: "English", fr: "Anglais" };
export const ITALIAN: Label = { it: "Italiano", en: "Italian", fr: "Italien" };
export const FRENCH: Label = { it: "Francese", en: "French", fr: "Français" };
export const ALL: Label = { it: "Tutti", en: "All", fr: "Tous" };
export const ALL_HEADER_TITLE: Label = {
  it: "Tutti i",
  en: "All",
  fr: "Tous les",
};

export const MISSING_CHANNEL_INFO: Label = {
  it: "Nessuna informazione disponibile per il canale",
  en: "No channel info available",
  fr: "Aucune information disponible pour le chanel",
};

export const MISSING_REPORT_DATA: Label = {
  it: "Nessuna informazione disponibile per il report",
  en: "No report info available",
  fr: "Aucune information disponible pour le report",
};

export const OVERVIEW_SCREEN_LABEL: Label = {
  it: "Overview",
  en: "Overview",
  fr: "Aperçu",
};

export const LENSES_SCREEN_LABEL: Label = {
  it: "Lenti",
  en: "Lenses",
  fr: "Lentilles",
};

export const REPORT_SCREEN_LABEL: Label = {
  it: "Daily Report",
  en: "Daily Report",
  fr: "Rapport",
};

export const SETTINGS_SCREEN_LABEL: Label = {
  it: "Impostazioni",
  en: "Settings",
  fr: "Configuration",
};

export const LENSES_TITLE: Label = {
  it: "Dettaglio Lenti",
  en: "Lens Details",
  fr: "Détail des lentilles",
};

export const BY_HEADER: Label = {
  it: "Di",
  en: "By",
  fr: "De",
};

export const TABLE_HEADER_TIME: Label = {
  it: "Periodo",
  en: "Time",
  fr: "Temps",
};

export const PERFORMANCE_TAB_LABEL: Label = {
  it: "Performance",
  en: "Performance",
  fr: "Prestation",
};

export const INFO_TAB_LABEL: Label = {
  it: "Info",
  en: "Info",
  fr: "Info",
};

export const INFO_LAB: Label = {
  it: "Lab info",
  en: "Lab info",
  fr: "Lab info",
};

export const ACTIVITY_SUMMARY_LABEL: Label = {
  en: "Activity Summary",
  it: "Sintesi Attività",
  fr: "Résumé de l'activité",
};

export const YDT_LABEL: Label = {
  en: "YTD ",
  it: "YTD ",
  fr: "YTD ",
};

export const FY_PY_LABEL: Label = {
  en: "FY ",
  it: "FY ",
  fr: "FY ",
};

export const FY_2PY_LABEL: Label = {
  en: "FY ",
  it: "FY ",
  fr: "FY ",
};

export const JOBS_LABEL: Label = {
  en: " (Jobs)",
  it: " (Jobs)",
  fr: " (Jobs)",
};

export const LAB_TYPE_LABEL: Label = {
  en: "Lab Type",
  it: "Tipo Laboratorio",
  fr: "Type de laboratoire",
};

export const PROCESS_LAB_LABEL: Label = {
  en: "Treatment Type",
  it: "Tipo Processo",
  fr: "Type de traitement",
};

export const LOCATION_LABEL: Label = {
  en: "Location",
  it: "Posizione",
  fr: "Emplacement",
};

export const OPENING_YEAR_LABEL: Label = {
  en: "Opening Year",
  it: "Anno di apertura",
  fr: "Année d'ouverture",
};

export const LAB_SURFACE_LABEL: Label = {
  en: "Lab surface",
  it: "Superficie del laboratorio",
  fr: "Surface de laboratoire",
};

export const LAST_UPDATE_LABEL: Label = {
  en: "Last update",
  it: "Ultimo aggiornamento",
  fr: "Dernière mise à jour",
};

export const HEAD_COUNT_LABEL: Label = {
  en: "Head count",
  it: "Organico",
  fr: "Nombre de têtes",
};

export const TOTAL_JOB_CY_LABEL: Label = {
  en: "Total lenses shipped ",
  it: "Totali lenti spedite ",
  fr: "Total lentilles expédiés ",
};

export const JOB_A_CY_LABEL: Label = {
  en: "Stock Lenses shipped ",
  it: "Lenti da Stock spedite ",
  fr: "Stock Lens expédiés ",
};

export const JOB_BC_CY_LABEL: Label = {
  en: "RX Lenses shipped ",
  it: "Lenti RX spedite ",
  fr: "RX Lens expédiés ",
};

export const TOTAL_JOB_PY_LABEL: Label = {
  en: "Total lenses shipped ",
  it: "Totali lenti spedite ",
  fr: "Total lentilles expédiés ",
};

export const JOB_A_PY_LABEL: Label = {
  en: "Stock Lenses shipped ",
  it: "Lenti da Stock spedite ",
  fr: "Stock Lens expédiés ",
};

export const JOB_BC_PY_LABEL: Label = {
  en: "RX Lenses shipped ",
  it: "Lenti RX spedite ",
  fr: "RX Lens expédiés ",
};

export const TOTAL_JOB_2PY_LABEL: Label = {
  en: "Total lenses shipped ",
  it: "Totali lenti spedite ",
  fr: "Total lentilles expédiés ",
};

export const JOB_A_2PY_LABEL: Label = {
  en: "Stock Lenses shipped ",
  it: "Lenti da Stock spedite ",
  fr: "Stock Lens expédiés ",
};

export const JOB_BC_2PY_LABEL: Label = {
  en: "RX Lenses shipped ",
  it: "Lenti RX spedite ",
  fr: "RX Lens expédiés ",
};

export const LAB_INFO_LABELS: { [name: string]: Label } = {
  kingOfLab: LAB_TYPE_LABEL,
  processInTheLab: PROCESS_LAB_LABEL,
  location: LOCATION_LABEL,
  openingYear: OPENING_YEAR_LABEL,
  labSurface: LAB_SURFACE_LABEL,
  lastUpdateDate: LAST_UPDATE_LABEL,
  headCount: HEAD_COUNT_LABEL,
  totalJobShippedCY: TOTAL_JOB_CY_LABEL,
  jobAShippedCY: JOB_A_CY_LABEL,
  jobBCShippedCY: JOB_BC_CY_LABEL,
  totalJobShippedPY: TOTAL_JOB_PY_LABEL,
  jobAShippedPY: JOB_A_PY_LABEL,
  jobBCShippedPY: JOB_BC_PY_LABEL,
  totalJobShipped2PY: TOTAL_JOB_2PY_LABEL,
  jobAShipped2PY: JOB_A_2PY_LABEL,
  jobBCShipped2PY: JOB_BC_2PY_LABEL,
};
