import { Row } from "@deb-95/react-native-table-component";
import React, { ReactNode, useContext } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import {
  LAB,
  CHANNEL,
  AGGREGATE_LAB,
  ROW_PADDING_LEFT,
} from "../../constants/appBaseConstants";
import {
  OVERVIEW_TABLE_ROW_MAX_HEIGHT,
  OVERVIEW_TABLE_ROW_MIN_HEIGHT,
} from "../../constants/mainStyles";
import {
  LENSES_CHANNEL_STACK,
  AGGREGATE_LIST_STACK,
  INFO_PAGE_STACK,
} from "../../constants/navigationConstants";
import { ThemeContext } from "../../context/ThemeContext";
import useScale, { Scales } from "../../hooks/useScale";
import navigationStore from "../../stores/navigationStore";
import { OverviewMeta } from "../../types/apiTypes";
import { VLabColors, LabeledObject } from "../../types/appTypes";
import { VLabNavigationState } from "../../types/storesTypes";

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    row: {
      flex: 1,
      backgroundColor: colors.overviewTableRowBackground,
      borderBottomWidth: 1,
      borderBottomColor: colors.tableBottomBorder,
      paddingLeft: moderateScale(ROW_PADDING_LEFT, 0.2),
    },
  });

type ClickableRowProps = {
  navigationState: VLabNavigationState;
  itemMeta: OverviewMeta;
  splitId: string;
  splitType: string | null;
  navigate: (to: string) => void;
  datas: ReactNode[];
  flexArr: number[];
};

const updateNavigation = (
  navStateSelectedLab: LabeledObject,
  dimensionType: string
) => {
  navigationStore.pushLab(navStateSelectedLab);
  navigationStore.pushNavigationStack(dimensionType);
};

const ClickableRow = ({
  navigationState,
  itemMeta,
  splitId,
  splitType,
  navigate,
  datas,
  flexArr,
}: ClickableRowProps) => {
  const { colors } = useContext(ThemeContext);
  const scale = useScale();

  return (
    <TouchableOpacity
      testID={"ClickableRow"}
      onPress={() => {
        if (splitType?.toUpperCase().includes(LAB)) {
          navigationStore.changeSelectedLab({ ...itemMeta });
          updateNavigation(navigationState.selectedLab, LAB);
          navigate(INFO_PAGE_STACK);
        } else if (splitId?.toUpperCase().includes(CHANNEL)) {
          navigationStore.changeSelectedChannel({ ...itemMeta });
          updateNavigation(navigationState.selectedLab, CHANNEL);
          navigate(LENSES_CHANNEL_STACK);
        } else {
          navigationStore.changeSelectedLab({ ...itemMeta });
          updateNavigation(navigationState.selectedLab, AGGREGATE_LAB);
          navigate(AGGREGATE_LIST_STACK);
        }
      }}
      style={{
        flex: 1,
        minHeight: scale.moderateVerticalScale(
          ...OVERVIEW_TABLE_ROW_MIN_HEIGHT
        ),
        maxHeight: scale.moderateVerticalScale(
          ...OVERVIEW_TABLE_ROW_MAX_HEIGHT
        ),
      }}
    >
      <Row data={datas} flexArr={flexArr} style={styles(colors, scale).row} />
    </TouchableOpacity>
  );
};

export default ClickableRow;
