import React, { useState, useEffect } from "react";
import { StyleSheet, ScrollView, Platform, View, Text } from "react-native";
import Constants from "expo-constants";

import SettingsService from "../services/settingService";
import { Info } from "../types/apiTypes";
import { ThemeContext } from "../context/ThemeContext";
import { VLabColors, CardItem } from "../types/appTypes";
import { cardData } from "../dataStructures/settingsScreenData";
import RefreshButton from "../components/RefreshButton";
import ProfileCard from "../components/ProfileCard";
import PreferencesCard from "../components/PreferencesCard";
import CollapsibleView from "../components/CollapsibleView";
import { SETTINGS_PAGE_TITLE } from "../constants/dictionary";
import useVLabAppState from "../hooks/useVLabAppState";
import useScale, { Scales } from "../hooks/useScale";
import CollapsibleViewTitle from "../components/CollapsibleViewTitle";
import CardRow from "../components/CardRow";

const SettingsScreen = () => {
  const [infoApi, setInfoApi] = useState<Info>();
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  const appState = useVLabAppState();

  useEffect(() => {
    const callInfoSubscription = SettingsService.callInfo().subscribe(
      setInfoApi
    );
    return () => {
      callInfoSubscription.unsubscribe();
    };
  }, []);

  if (!appState) {
    return null;
  }

  const { selectedLanguage } = appState;
  const cards = cardData(infoApi, selectedLanguage, Constants.manifest.version);

  return (
    <ScrollView
      contentContainerStyle={[styles(colors, scaleFunctions).container]}
      showsVerticalScrollIndicator={false}
    >
      <ProfileCard appState={appState} />
      <CollapsibleView
        title={
          <CollapsibleViewTitle
            text={SETTINGS_PAGE_TITLE[selectedLanguage]}
            icon="settings"
            type="material"
          />
        }
        arrowStyling={{ color: colors.text }}
        style={styles(colors, scaleFunctions).collapsibleWrapper}
        collapsibleContainerStyle={
          styles(colors, scaleFunctions).collapsibleView
        }
      >
        <PreferencesCard appState={appState} />
      </CollapsibleView>

      {cards.map((card, i) => {
        return (
          <CollapsibleView
            arrowStyling={{ color: colors.text }}
            style={styles(colors, scaleFunctions).collapsibleWrapper}
            collapsibleContainerStyle={
              styles(colors, scaleFunctions).collapsibleView
            }
            key={"card" + i}
            title={
              <CollapsibleViewTitle
                icon={card.head.icon}
                type={card.head.type}
                text={card.head.title}
              />
            }
          >
            <View
              key={"card_intern" + i}
              style={{
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              {card.items.map((el: CardItem, j: number) => {
                return (
                  <CardRow
                    key={"CardRow" + j}
                    title={el.title}
                    value={el.value}
                  />
                );
              })}
            </View>
          </CollapsibleView>
        );
      })}

      {Platform.OS === "web" && (
        <View style={{ flex: 1, flexDirection: "column" }}>
          <Text style={{ minWidth: 0, width: "auto" }}> </Text>
          <RefreshButton selectedLanguage={appState.selectedLanguage} />
          <Text style={{ minWidth: 0, width: "auto" }}> </Text>
        </View>
      )}
    </ScrollView>
  );
};

export default SettingsScreen;

const styles = (colors: VLabColors, {}: Scales) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    preference: {
      flexDirection: "row",
      paddingVertical: 12,
      paddingHorizontal: 16,
    },
    collapsibleWrapper: {
      backgroundColor: colors.cardTitleBackgroundColor,
      shadowOpacity: 0,
    },
    collapsibleView: {
      backgroundColor: colors.cardBodyBackgroundColor,
    },
  });
