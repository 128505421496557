import React from "react";
import { StyleSheet, View } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import useScale, { Scales } from "../hooks/useScale";

const VlProgressBar = (props: { progress: number }) => {
  const { progress } = props;
  const { colors } = React.useContext(ThemeContext);
  const { top } = useSafeAreaInsets();
  const scaleFunctions = useScale();

  const VlProgressBarStyle = ({ moderateScale }: Scales) =>
    StyleSheet.create({
      container: {
        height: moderateScale(4, 0.2),
        backgroundColor: colors.background,
        marginTop: top,
      },
      filler: {
        width: `${progress}%`,
        height: moderateScale(4, 0.2),
        backgroundColor: colors.timeBarBackground,
      },
    });

  return (
    <View style={VlProgressBarStyle(scaleFunctions).container}>
      <View style={VlProgressBarStyle(scaleFunctions).filler} />
    </View>
  );
};

export default VlProgressBar;
