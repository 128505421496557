import React from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";

import ThemeProvider from "./src/components/ThemeProvider";
import Main from "./src/components/Main";
import * as Font from "expo-font";
import { AppLoading } from "expo";
import { Ionicons } from "@expo/vector-icons";
import appStore from "./src/stores/appStore";
import { GENERIC_ERROR_MESSAGE } from "./src/constants/dictionary";
import GlobalStatesProvider from "./src/components/GlobalStatesProvider";

async function loadResourcesAndDataAsync() {
  await Promise.all([
    Font.loadAsync({
      ...Ionicons.font,
      "space-mono": require("./assets/fonts/SpaceMono-Regular.ttf"),
      lato: require("./assets/fonts/Lato-Regular.ttf"),
      "Lato-Medium": require("./assets/fonts/Lato-Medium.ttf"),
      "Lato-Bold": require("./assets/fonts/Lato-Bold.ttf"),
    }),
  ]);
}
export default class App extends React.Component {
  state = {
    isLoadingComplete: false,
  };

  static getDerivedStateFromError(error: Error) {
    appStore.setError(
      true,
      GENERIC_ERROR_MESSAGE[appStore.getSelectedLanguage()],
      error.message
    );
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);
  }

  render() {
    if (!this.state.isLoadingComplete) {
      return (
        <AppLoading
          startAsync={loadResourcesAndDataAsync}
          onFinish={() => this.setState({ isLoadingComplete: true })}
        />
      );
    } else {
      return (
        <SafeAreaProvider>
          <ThemeProvider>
            <GlobalStatesProvider>
              <Main />
            </GlobalStatesProvider>
          </ThemeProvider>
        </SafeAreaProvider>
      );
    }
  }
}
