import { StyleSheet } from "react-native";
import { ScaleFunctionArgs } from "../hooks/useScale";

export const DEFAULT_FLEX = { flex: 1 };

export const VIEW_STYLES = StyleSheet.create({
  row: {
    ...DEFAULT_FLEX,
    flexDirection: "row",
  },
  column: {
    ...DEFAULT_FLEX,
    flexDirection: "column",
  },
  centeredFlex: {
    flex: 1,
    justifyContent: "center",
  },
});

export const LENSES_TABLE_ROW_MIN_HEIGHT: ScaleFunctionArgs = [28, 0.1];
export const LENSES_TABLE_ROW_MAX_HEIGHT: ScaleFunctionArgs = [28, 0.1];
export const LENSES_TABLE_HEAD_HEIGHT: ScaleFunctionArgs = [58, 0.1];
export const OVERVIEW_TABLE_HEAD_HEIGHT: ScaleFunctionArgs = [58, 0.1];
export const OVERVIEW_TABLE_ROW_MIN_HEIGHT: ScaleFunctionArgs = [44, 0.1];
export const OVERVIEW_TABLE_ROW_MAX_HEIGHT: ScaleFunctionArgs = [44, 0.1];
export const OVERVIEW_TABLE_FIRST_ROW_HEIGHT: ScaleFunctionArgs = [64, 0.1];
export const TABLE_HEAD_PADDING: ScaleFunctionArgs = [7, 0.1];
export const TABLE_FONT_SIZE: ScaleFunctionArgs = [13, 0.2];
export const TABLE_TITLE_FONT_SIZE: ScaleFunctionArgs = [12, 0.3];
export const TABLE_UNIT_MEASUREMENT_SIZE: ScaleFunctionArgs = [9, 0.3];
export const HEADER_FONT_SIZE: ScaleFunctionArgs = [14, 0.5];
export const TIME_BAR_FONT_SIZE: ScaleFunctionArgs = [12, 0.2];
export const TIME_BAR_INFO_FONT_SIZE: ScaleFunctionArgs = [10, 0.2];

export const LATERAL_DESKTOP_VIEW_PADDING_WIDTH = 300;

export const BOLD_FONT = "Arial";
export const MEDIUM_FONT = "Arial";
export const BASE_FONT = "Arial";

export const LATO_BOLD_FONT = "Lato-Bold";
export const LATO_MEDIUM_FONT = "Lato-Medium";
export const LATO_BASE_FONT = "Lato";

export const FONT_SIZE_SETTINGS: ScaleFunctionArgs = [14, 0.1];

export const DEFAULT_HEX_OPACITY = 0.2;
