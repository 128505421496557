import React, { useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { Image } from "react-native-elements";
import { LATERAL_DESKTOP_VIEW_PADDING_WIDTH } from "../../constants/mainStyles";
import useLayout from "../../hooks/useLayout";
import useScale, { Scales } from "../../hooks/useScale";
import { VLabColors } from "../../types/appTypes";
import { ThemeContext } from "../../context/ThemeContext";
import { isLargeScreen } from "../../utils/screenUtils";
import GalleryIconSvg from "../svgs/GalleryIconSvg";

type LabImageProps = {
  images: string;
  setImgIsPressed: (isOpen: boolean) => void;
  numberImgToShow: number;
};

export const LabImage = ({
  images,
  setImgIsPressed,
  numberImgToShow,
}: LabImageProps) => {
  const { colors } = useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { width } = useLayout();

  return (
    <View
      style={{
        display: "flex",
      }}
    >
      {numberImgToShow > 0 && (
        <TouchableOpacity
          onPress={() => setImgIsPressed(true)}
          style={styles(colors, scaleFunctions, width).iconCounterContainer}
        >
          <GalleryIconSvg color={colors.text} />
          <Text style={styles(colors, scaleFunctions, width).counterImgText}>
            {`+ ${numberImgToShow}`}
          </Text>
        </TouchableOpacity>
      )}
      <Image
        source={{ uri: images }}
        style={styles(colors, scaleFunctions, width).mainPicture}
      />
    </View>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales, width: number) =>
  StyleSheet.create({
    iconCounterContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      width: moderateScale(45, 0.3),
      height: moderateScale(20, 0.3),
      zIndex: 200,
      left: isLargeScreen(width)
        ? width - 2 * LATERAL_DESKTOP_VIEW_PADDING_WIDTH - 80
        : width - 60,
      backgroundColor: colors.cardBodyBackgroundColor,
      paddingBottom: 2,
      top: 8,
      borderRadius: 25,
      opacity: 0.7,
    },
    miniIcon: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    counterImgText: {
      color: "white",
      fontSize: moderateScale(11, 0.2),
      display: "flex",
      justifyContent: "center",
      paddingLeft: 3,
    },
    mainPicture: {
      width: "100%",
      height: moderateScale(210, 0.3),
    },
  });
