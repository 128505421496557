import { HeaderBackButton } from "@react-navigation/stack";
import React, { useContext, useRef, useEffect, ReactNode } from "react";
import HeaderBackIcon from "../components/HeaderBackIcon";
import { BASE_FONT, HEADER_FONT_SIZE } from "../constants/mainStyles";
import { GlobalStatesContext } from "../context/GlobalStatesContext";
import navigationStore from "../stores/navigationStore";
import useScale from "./useScale";
import { ALL_LABELED_OBJECT } from "../constants/appBaseConstants";
import { useNavigation } from "@react-navigation/native";
import { ROOT, AGGREGATE_LIST_STACK } from "../constants/navigationConstants";
import { LAB, CHANNEL } from "../constants/appBaseConstants";

const useBackToPreviousLab = () => {
  const { navigationState, appState } = useContext(GlobalStatesContext);
  const { moderateScale } = useScale();
  const backComponent = useRef<ReactNode>();
  const navigation = useNavigation();

  const changeNavigation = (destinationStack: string) => {
    navigationStore.popNavigationStack();
    navigation.navigate(destinationStack);
  };

  const backButtonHandler = () => {
    if (navigationState.labStack.length === 1) {
      changeNavigation(ROOT);
    } else if (
      navigationState.labStack.length === 2 &&
      navigationState.navigationStack[navigationState.labStack.length - 1] ===
        LAB
    ) {
      changeNavigation(AGGREGATE_LIST_STACK);
    } else if (
      navigationState.labStack.length === 2 &&
      navigationState.navigationStack[navigationState.labStack.length - 1] ===
        CHANNEL
    ) {
      navigation.goBack();
    }
  };

  useEffect(() => {
    backComponent.current =
      navigationState.labStack.length > 0 ? (
        <HeaderBackButton
          backImage={({ tintColor }) => (
            <HeaderBackIcon tintColor={tintColor} />
          )}
          onPress={() => {
            backButtonHandler();
            navigationStore.popLab();
            navigationStore.changeSelectedChannel(ALL_LABELED_OBJECT);
          }}
          labelVisible={true}
          label={
            navigationStore.getPreviousLab()?.labels[appState.selectedLanguage]
          }
          labelStyle={{
            fontFamily: BASE_FONT,
            fontSize: moderateScale(...HEADER_FONT_SIZE),
            maxWidth: moderateScale(50, 1.3),
            textOverflow: "ellipsis",
          }}
        />
      ) : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appState.selectedLanguage,
    navigationState.selectedLab,
    navigationState.labStack,
  ]);
  return backComponent;
};

export default useBackToPreviousLab;
