import React from "react";
import { Button } from "react-native-elements";
import { REFRESH } from "../constants/dictionary";
import { StyleSheet } from "react-native";
import { VLabColors } from "../types/appTypes";
import { ThemeContext } from "../context/ThemeContext";
import { LanguagesEnum } from "../types/appEnums";
import { BOLD_FONT, FONT_SIZE_SETTINGS } from "../constants/mainStyles";
import useScale, { Scales } from "../hooks/useScale";

type RefreshButtonProps = {
  selectedLanguage: LanguagesEnum;
};

const RefreshButton = ({ selectedLanguage }: RefreshButtonProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  return (
    <Button
      buttonStyle={styles(colors, scaleFunctions).refreshButtonContainer}
      titleStyle={styles(colors, scaleFunctions).refreshButtonTitle}
      title={REFRESH[selectedLanguage]}
      onPress={() => {
        location.replace("/");
        caches.keys().then(function (names) {
          for (const name of names) {
            caches.delete(name);
          }
        });
      }}
    />
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    refreshButtonContainer: {
      backgroundColor: colors.tint,
      marginVertical: 10,
      marginHorizontal: "auto",
      width: 250,
      borderRadius: 0,
    },
    refreshButtonTitle: {
      color: colors.text,
      fontSize: moderateScale(...FONT_SIZE_SETTINGS),
      fontFamily: BOLD_FONT,
      fontWeight: "bold",
    },
  });

export default RefreshButton;
