import * as React from "react";

import {
  StackScreenProps,
  StackHeaderTitleProps,
} from "@react-navigation/stack";
import { RootStackParamList, LensesParamList } from "../types/navigationTypes";
import { BottomTabScreenProps } from "@react-navigation/bottom-tabs";
import navigationStore from "../stores/navigationStore";
import mainService from "../services/mainService";
import { LensesData } from "../types/apiTypes";
import LensesPage from "../components/lenses/LensesPage";
import appStore from "../stores/appStore";
import HeaderTitleComponent from "../components/HeaderTitleComponent";
import { getLensesTitle } from "../utils/headerUtils";
import { GlobalStatesContext } from "../context/GlobalStatesContext";
import useBackToPreviousLab from "../hooks/useBackToPreviousLab";

type LensesScreenProps =
  | StackScreenProps<RootStackParamList, "LensesChannelStack">
  | BottomTabScreenProps<LensesParamList, "LensesScreen">;

const LensesScreen: React.FC<LensesScreenProps> = ({
  navigation,
}: LensesScreenProps) => {
  const { appState, navigationState } = React.useContext(GlobalStatesContext);
  const [lensesData, setLensesData] = React.useState<LensesData>();
  const [isLoading, setIsLoading] = React.useState(false);
  const backComponent = useBackToPreviousLab();

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerLeft: () => navigation.canGoBack() && backComponent.current,
    });

    const lensesTitle = getLensesTitle(
      navigationState.selectedChannel,
      navigationState.selectedLab,
      appState.selectedLanguage,
      appState.isChannelGroup
    );
    navigation.setOptions({
      headerTitle: (props: StackHeaderTitleProps) => (
        <HeaderTitleComponent {...props} title={lensesTitle.title} />
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    appState.selectedLanguage,
    navigationState.selectedChannel,
    navigationState.selectedLab,
    navigationState.labStack,
    appState.isChannelGroup,
  ]);

  React.useEffect(() => {
    appStore.setLoadingGauge(30);
    setIsLoading(true);
    mainService
      .callLenses({
        lab: navigationState.selectedLensesFilter,
        channel: navigationState.selectedChannel.id,
        time: appState.selectedTimeFilter,
      })
      .subscribe((res) => {
        appStore.setLoadingGauge(100);
        setLensesData(res);
        setIsLoading(false);
        const newFilters = res.table.navBar;
        if (
          newFilters.length > 0 &&
          newFilters[0]?.id !== navigationState.lensesFilters[0]?.id
        ) {
          navigationStore.changeLensesFilters(newFilters);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    navigationState.selectedChannel,
    appState.selectedTimeFilter,
    navigationState.selectedLensesFilter,
  ]);

  return (
    <LensesPage
      appState={appState}
      lensesData={lensesData}
      isLoading={isLoading}
    />
  );
};

export default React.memo(LensesScreen);
