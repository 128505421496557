import React from "react";
import {
  BehaviorSubject,
  forkJoin,
  from /*AjaxError, AjaxResponse*/,
} from "rxjs";

import { AppState, User, VLabAppStore } from "../types/storesTypes";
import {
  LANGUAGES,
  LOCAL_STORAGE_LANGUAGE_NAME,
} from "../constants/appBaseConstants";
import { TimeFiltersEnum, LanguagesEnum, ThemeEnum } from "../types/appEnums";
import SettingService from "../services/settingService";

import { LOCAL_STORAGE_THEME_NAME } from "../constants/appBaseConstants";
import AsyncStorage from "@react-native-community/async-storage";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { Initialization } from "@microsoft/applicationinsights-web/dist-esm/Initialization";

const defaultState: AppState = {
  languages: LANGUAGES,
  selectedLanguage: LanguagesEnum.EN,
  selectedTimeFilter: TimeFiltersEnum.WTD,
  darkMode: true,
  hasError: false,
  loadGauge: 0,
  user: {
    firstName: "",
    lastName: "",
    admin: false,
    preferences: {},
    email: "",
    sub: "",
  },
  isChannelGroup: false,
  appInsightsObj: undefined,
};

const subject = new BehaviorSubject<AppState>(defaultState);

let state = defaultState;

const appStore: VLabAppStore = {
  initSettings: (userResponse: User) => {
    forkJoin([
      from(AsyncStorage.getItem(LOCAL_STORAGE_LANGUAGE_NAME)),
      from(AsyncStorage.getItem(LOCAL_STORAGE_THEME_NAME)),
    ]).subscribe(([storageLanguage, storageTheme]) => {
      const user: User = Object.assign(
        {},
        ...Object.keys(state.user).map((key) => ({
          [key]: userResponse[key as keyof User],
        }))
      );
      // priority for preferences goes to local storage preference
      const selectedLanguage = (storageLanguage as LanguagesEnum)
        ? storageLanguage
        : user.preferences.language;
      const theme = storageTheme ? storageTheme : user.preferences.theme;
      state = {
        ...state,
        user,
        selectedLanguage:
          (selectedLanguage as LanguagesEnum) || LanguagesEnum.EN,
        darkMode: theme ? theme === ThemeEnum.DARK : true,
      };
      subject.next({ ...state });
    });
  },
  subscribe: (setState: React.Dispatch<AppState>) =>
    subject.subscribe(setState),
  changeTimeFilter: (filterId: TimeFiltersEnum) => {
    state = {
      ...state,
      selectedTimeFilter: filterId,
    };
    subject.next({ ...state });
  },
  changeLanguage: (languageId: LanguagesEnum) => {
    SettingService.callSetPreferences({
      language: languageId,
    }).subscribe((_) => {
      state = { ...state, selectedLanguage: languageId };
      AsyncStorage.setItem(LOCAL_STORAGE_LANGUAGE_NAME, languageId);
      subject.next({ ...state });
    });
  },
  changeDarkMode: (themeId: ThemeEnum) => {
    SettingService.callSetPreferences({
      theme: themeId,
    }).subscribe((_) => {
      state = { ...state, darkMode: ThemeEnum.DARK === themeId };
      AsyncStorage.setItem(LOCAL_STORAGE_THEME_NAME, themeId);
      subject.next({ ...state });
    });
  },
  getSelectedLanguage: () => {
    return `${state.selectedLanguage}` as LanguagesEnum;
  },
  getAppInsights: () => {
    return state.appInsightsObj as Initialization;
  },
  setLoadingGauge: (value: number) => {
    state = { ...state, loadGauge: value };
    subject.next({ ...state });
  },
  setIsChannelGroup: (flag: boolean) => {
    state = { ...state, isChannelGroup: flag };
    subject.next({ ...state });
  },
  setAppInsights: (appInsightsObj: ApplicationInsights) => {
    state = { ...state, appInsightsObj: appInsightsObj };
    subject.next(state);
  },
  setError: (
    errorStatus: boolean,
    errorMessage?: string,
    msgDetails?: string
  ) => {
    state = { ...state, hasError: errorStatus, errorMessage, msgDetails };
    subject.next({ ...state });
  },
  default: state,
};

export default appStore;
