import React from "react";
import { GlobalStatesContext } from "../context/GlobalStatesContext";
import useVLabAppState from "../hooks/useVLabAppState";
import useVLabNavigationState from "../hooks/useVLabNavigationState";

type GlobalStatesProviderProps = unknown;

const GlobalStatesProvider: React.FC<GlobalStatesProviderProps> = (props) => {
  const appState = useVLabAppState();
  const navigationState = useVLabNavigationState();

  if (!appState || !navigationState) {
    return null;
  }

  return (
    <GlobalStatesContext.Provider
      value={{
        appState,
        navigationState,
      }}
    >
      {props.children}
    </GlobalStatesContext.Provider>
  );
};

export default GlobalStatesProvider;
