import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import ReportScreen from "../screens/ReportScreen";
import SettingsScreen from "../screens/SettingsScreen";
import {
  BottomTabParamList,
  OverviewParamList,
  SettingsParamList,
  ReportParamList,
} from "../types/navigationTypes";
import { ThemeContext } from "../context/ThemeContext";
import {
  OVERVIEW_SCREEN,
  OVERVIEW,
  SETTINGS,
  SETTINGS_SCREEN,
  headerSettings,
  REPORT,
  REPORT_SCREEN,
} from "../constants/navigationConstants";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import GraphSvg from "../components/svgs/GraphSvg";
import ReportSvg from "../components/svgs/ReportSvg";
import SettingsSvg from "../components/svgs/SettingsSvg";
import useScale from "../hooks/useScale";
import useVLabAppState from "../hooks/useVLabAppState";
import {
  OVERVIEW_SCREEN_LABEL,
  REPORT_SCREEN_LABEL,
  SETTINGS_SCREEN_LABEL,
} from "../constants/dictionary";
import TabButton from "./TabButton";
import { MEDIUM_FONT } from "../constants/mainStyles";
import InternalTabNavigation from "./InternalTabNavigation";

const BottomTab = createBottomTabNavigator<BottomTabParamList>();

export default function BottomTabNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { moderateScale } = scaleFunctions;
  const appState = useVLabAppState();
  const { bottom } = useSafeAreaInsets();

  return (
    <BottomTab.Navigator
      initialRouteName={OVERVIEW}
      tabBarOptions={{
        activeTintColor: colors.tabIconSelected,
        inactiveTintColor: colors.bottomNavigationDefaultColor,
        inactiveBackgroundColor: colors.bottomNavigationDefaultBackground,
        adaptive: true,
        labelStyle: {
          fontFamily: MEDIUM_FONT,
        },
        tabStyle: {
          padding: moderateScale(3, 0.2),
          paddingBottom: moderateScale(7, 0.2),
        },
        style: {
          height: moderateScale(50, 0.2) + bottom,
          backgroundColor: colors.bottomNavigationDefaultBackground,
        },
        safeAreaInsets: { bottom: bottom ? bottom - 10 : bottom },
      }}
    >
      <BottomTab.Screen
        name={OVERVIEW}
        component={OverviewNavigator}
        options={{
          tabBarButton: (props) => <TabButton {...props} />,
          tabBarLabel:
            appState && OVERVIEW_SCREEN_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <GraphSvg color={color} />,
        }}
      />
      <BottomTab.Screen
        name={REPORT}
        component={ReportNavigator}
        options={{
          tabBarButton: (props) => <TabButton {...props} />,
          tabBarLabel:
            appState && REPORT_SCREEN_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <ReportSvg color={color} />,
        }}
      />
      <BottomTab.Screen
        name={SETTINGS}
        component={SettingsNavigator}
        options={{
          tabBarButton: (props) => <TabButton {...props} />,
          tabBarLabel:
            appState && SETTINGS_SCREEN_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <SettingsSvg color={color} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const OverviewStack = createStackNavigator<OverviewParamList>();

function OverviewNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <OverviewStack.Navigator screenOptions={{ headerShown: false }}>
      <OverviewStack.Screen
        name={OVERVIEW_SCREEN}
        component={InternalTabNavigation}
        options={{
          ...headerSettings(colors, scaleFunctions, 0),
        }}
      />
    </OverviewStack.Navigator>
  );
}

const ReportStack = createStackNavigator<ReportParamList>();

function ReportNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <ReportStack.Navigator>
      <ReportStack.Screen
        name={REPORT_SCREEN}
        component={ReportScreen}
        options={{ ...headerSettings(colors, scaleFunctions) }}
      />
    </ReportStack.Navigator>
  );
}

const SettingsStack = createStackNavigator<SettingsParamList>();

function SettingsNavigator() {
  return (
    <SettingsStack.Navigator>
      <SettingsStack.Screen
        name={SETTINGS_SCREEN}
        component={SettingsScreen}
        options={{
          headerShown: false,
        }}
      />
    </SettingsStack.Navigator>
  );
}
