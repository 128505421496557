import { Table, Row, TableWrapper } from "@deb-95/react-native-table-component";
import React, { ReactNode } from "react";
import { StyleSheet, View, ViewStyle } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import {
  REPORT_CELL_PADDING_RIGHT,
  REPORT_TABLE_HEADER_ELEMENTS,
} from "../../constants/appBaseConstants";
import { TABLE_HEADER_EMPTY } from "../../constants/dictionary";
import {
  BASE_FONT,
  LATO_BASE_FONT,
  LENSES_TABLE_HEAD_HEIGHT,
  LENSES_TABLE_ROW_MAX_HEIGHT,
  LENSES_TABLE_ROW_MIN_HEIGHT,
} from "../../constants/mainStyles";
import { ThemeContext } from "../../context/ThemeContext";
import useScale, { Scales } from "../../hooks/useScale";
import { ReportItem } from "../../types/apiTypes";
import { LanguagesEnum } from "../../types/appEnums";
import { VLabColors } from "../../types/appTypes";
import { brightenUp } from "../../utils/colorsUtils";
import { formatNumber } from "../../utils/formatNumber";
import { getReportTable } from "../../utils/formatReport";
import { TableText } from "../TableText";

type ReportTableProps = {
  tableData: ReportItem[];
  selectedLanguage: LanguagesEnum;
  service: number | null;
};

const flexArr = [2, 1, 1];

const ReportTable: React.FC<ReportTableProps> = ({
  tableData,
  selectedLanguage,
}) => {
  const { colors } = React.useContext(ThemeContext);
  const scale = useScale();

  const { firstTable, secondTable } = getReportTable(
    tableData,
    selectedLanguage
  );

  const getTableHead = () => [
    <TableText
      key={`headNull`}
      alignment={"left"}
      color={colors.text}
      bgCol={colors.tableRowBgCol}
      fontFam={BASE_FONT}
      additionalStyle={{
        paddingRight: scale.moderateScale(REPORT_CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {TABLE_HEADER_EMPTY[selectedLanguage]}
    </TableText>,

    ...REPORT_TABLE_HEADER_ELEMENTS(selectedLanguage).map(
      (reportHeaderElement: [string, string], index: number) => (
        <View
          key={`head${index}`}
          style={[
            {
              flex: 1,
              flexDirection: "column",
            },
          ]}
        >
          <TableText
            color={index === 0 ? colors.orderedText : colors.text}
            alignment={"right"}
            justifyContent={"flex-end"}
            bgCol={
              index % 2 !== 1
                ? brightenUp(colors.tableRowBgCol)
                : colors.tableRowBgCol
            }
            additionalStyle={{
              paddingRight: scale.moderateScale(REPORT_CELL_PADDING_RIGHT, 0.2),
            }}
          >
            {`${reportHeaderElement[0]
              .charAt(0)
              .toUpperCase()}${reportHeaderElement[0].slice(1)}`}
          </TableText>
          <TableText
            color={index === 0 ? colors.orderedText : colors.text}
            alignment={"right"}
            justifyContent={"flex-start"}
            bgCol={
              index % 2 !== 1
                ? brightenUp(colors.tableRowBgCol)
                : colors.tableRowBgCol
            }
            additionalStyle={{
              fontSize: scale.moderateScale(9, 0.2),
              paddingRight: scale.moderateScale(REPORT_CELL_PADDING_RIGHT, 0.2),
            }}
          >
            {`(${reportHeaderElement[1]})`}
          </TableText>
        </View>
      )
    ),
    TABLE_HEADER_EMPTY[selectedLanguage],
  ];

  const getRowsContent = (item: ReportItem, idx: number, bgColor: string) => [
    <TableText
      key={"rowContentID" + idx}
      alignment={"left"}
      color={colors.text}
      bgCol={bgColor}
      fontFam={BASE_FONT}
      additionalStyle={{
        paddingRight: scale.moderateScale(REPORT_CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {idx === 0
        ? item.id.charAt(0).toUpperCase() + item.id.slice(1).toLowerCase()
        : item.id}
    </TableText>,
    <TableText
      key={"rowContentOrders" + idx}
      alignment={"right"}
      color={colors.text}
      bgCol={brightenUp(bgColor)}
      fontFam={LATO_BASE_FONT}
      additionalStyle={{
        paddingRight: scale.moderateScale(REPORT_CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {formatNumber(item.orders, selectedLanguage)}
    </TableText>,
    <TableText
      key={"rowContentShipped" + idx}
      alignment={"right"}
      color={colors.text}
      bgCol={bgColor}
      fontFam={LATO_BASE_FONT}
      additionalStyle={{
        paddingRight: scale.moderateScale(REPORT_CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {formatNumber(item.shipments, selectedLanguage)}
    </TableText>,
  ];

  const divider = (
    <Row
      data={[]}
      style={{
        height: scale.moderateScale(...LENSES_TABLE_ROW_MIN_HEIGHT),
      }}
    />
  );

  const getTable = (
    tableToRender: ReportItem[],
    isFirstTable = true
  ): ReactNode => {
    return tableToRender.length > 0 ? (
      tableToRender.map((item: ReportItem, idx: number) => (
        <Row
          testID={isFirstTable ? "firstTable" : "secondTable"}
          key={`firstTableRow${idx}`}
          data={getRowsContent(
            item,
            idx,
            idx === 0 && !isFirstTable
              ? colors.splitHeaderBg
              : colors.tableRowBgCol
          )}
          flexArr={flexArr}
          style={[
            styles(colors, scale).row,
            !isFirstTable && {
              backgroundColor:
                idx === 0 ? colors.splitHeaderBg : colors.tableRowBgCol,
            },
          ]}
        />
      ))
    ) : (
      <Row
        data={[
          <TableText key={"noData"} alignment={"left"} color={colors.text} />,
          <TableText key={"noData"} alignment={"right"} color={colors.text} />,
          <TableText key={"noData"} alignment={"right"} color={colors.text} />,
          "",
        ]}
        flexArr={flexArr}
        style={styles(colors, scale).row}
      />
    );
  };

  return (
    <Table style={{ flex: 1, backgroundColor: colors.tableRowBgCol }}>
      <Row
        data={getTableHead()}
        flexArr={flexArr}
        style={styles(colors, scale).tableHeaderContainer}
      />
      <TableWrapper style={{ flex: 1 }}>
        <ScrollView
          contentContainerStyle={{ flex: 1 }}
          showsVerticalScrollIndicator={false}
        >
          {getTable(firstTable)}
          {divider}
          {getTable(secondTable, false)}
          {divider}
        </ScrollView>
      </TableWrapper>
    </Table>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) => {
  const LENSES_TABLE_ROW_STYLE: ViewStyle = {
    minHeight: moderateScale(...LENSES_TABLE_ROW_MIN_HEIGHT),
    maxHeight: moderateScale(...LENSES_TABLE_ROW_MAX_HEIGHT),
  };
  const LENSES_TABLE_HEADER_STYLE: ViewStyle = {
    minHeight: moderateScale(...LENSES_TABLE_HEAD_HEIGHT),
    maxHeight: moderateScale(...LENSES_TABLE_HEAD_HEIGHT),
  };
  return StyleSheet.create({
    tableHeaderContainer: {
      flex: 1,
      ...LENSES_TABLE_HEADER_STYLE,
      paddingLeft: moderateScale(16, 0.2),
      borderBottomColor: colors.tableBottomBorder,
      borderBottomWidth: 1,
    },
    row: {
      flex: 1,
      ...LENSES_TABLE_ROW_STYLE,
      borderBottomColor: colors.tableBottomBorder,
      borderBottomWidth: 1,
      paddingLeft: moderateScale(16, 0.2),
    },
  });
};

export default React.memo(ReportTable);
