import Constants from "expo-constants";
import { LanguagesEnum, TimeFiltersEnum } from "../types/appEnums";
import {
  TIME_SHORT_LABELS,
  ITALIAN,
  FRENCH,
  ENGLISH,
  ALL,
  TABLE_HEADER_ORDERED,
  TABLE_HEADER_SHIPPED,
  TABLE_HEADER_QUALITY,
  TABLE_HEADER_DAYS,
  TABLE_HEADER_SERVICE,
} from "./dictionary";
import { Filter, Language, LabeledObject } from "../types/appTypes";

export const LOCAL_STORAGE_TOKEN_NAME = "VLabToken";
export const LOCAL_STORAGE_LOGGED_NAME = "VLabLoggedUser";
export const TOKEN_HEADER_NAME = "X-Auth-Token";
export const TIME_FILTERS: Filter[] = [
  {
    id: TimeFiltersEnum.DAY,
    labels: TIME_SHORT_LABELS[TimeFiltersEnum.DAY],
  },
  {
    id: TimeFiltersEnum.WTD,
    labels: TIME_SHORT_LABELS[TimeFiltersEnum.WTD],
  },
  {
    id: TimeFiltersEnum.MTD,
    labels: TIME_SHORT_LABELS[TimeFiltersEnum.MTD],
  },
  {
    id: TimeFiltersEnum.QTD,
    labels: TIME_SHORT_LABELS[TimeFiltersEnum.QTD],
  },
  {
    id: TimeFiltersEnum.YTD,
    labels: TIME_SHORT_LABELS[TimeFiltersEnum.YTD],
  },
];

export const LANGUAGES: Language[] = [
  { id: LanguagesEnum.EN, labels: ENGLISH },
  { id: LanguagesEnum.IT, labels: ITALIAN },
  { id: LanguagesEnum.FR, labels: FRENCH },
];

export const SIGMA_SYMBOL = "Σ";
export const UNIT_OF_MEASURE = "Jobs";

export const TABLE_HEADER_ELEMENTS = (
  selectedLanguage: LanguagesEnum
): [string, string][] => [
  [TABLE_HEADER_ORDERED[selectedLanguage], UNIT_OF_MEASURE],
  [TABLE_HEADER_SHIPPED[selectedLanguage], UNIT_OF_MEASURE],
  [TABLE_HEADER_DAYS[selectedLanguage], TABLE_HEADER_SERVICE[selectedLanguage]],
  [TABLE_HEADER_QUALITY[selectedLanguage], "%"],
];

export const REPORT_TABLE_HEADER_ELEMENTS = (
  selectedLanguage: LanguagesEnum
): [string, string][] => [
  [TABLE_HEADER_ORDERED[selectedLanguage], UNIT_OF_MEASURE],
  [TABLE_HEADER_SHIPPED[selectedLanguage], UNIT_OF_MEASURE],
];

const environment = Constants.manifest.extra?.env || "";
export const LOCAL_STORAGE_THEME_NAME = `VLThemePref${environment}`;
export const LOCAL_STORAGE_LANGUAGE_NAME = `VLLanguagePref${environment}`;
export const ALL_LABELED_OBJECT: LabeledObject = {
  id: "ALL",
  labels: ALL,
};

export const LAB = "LAB";

export const CHANNEL = "CHANNEL";

export const AGGREGATE_LAB = "AGGREGATE_LAB";

export const NO_DATA_NUMBER = "-";

export const NOT_AVIABLE_LABEL = "n.a.";

export const CARD_TABLE_TITLES = ["TIME", "ORD", "SHI"];

export const MAX_FILTER_DETAILS_LENGTH = 5;

export const CELL_PADDING_RIGHT = 5;

export const REPORT_CELL_PADDING_RIGHT = 16;

export const ROW_PADDING_LEFT = 7;

export const NUMBER_OF_QUARTER = 4;

export const LAST_CELL_PADDING_RIGHT = 10;

export const LAB_LABEL = "Lab";

export const SQUARE_METERS_LABEL = "m²";

export const BRAZIL_LAB_CODE = "L010";

export const JAPAN_LAB_CODE = "JP01";

export const IS_LOCAL = () => process.env.NODE_ENV !== "production";
