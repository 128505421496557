import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="25" height="25" viewBox="0 0 25 11">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <Path
              d="M0 0L25 0 25 25 0 25z"
              transform="translate(-258 -657) translate(0 646) translate(258 3)"
            />
            <Path
              fill={color}
              fill-rule="nonzero"
              d="M24.268 9.665h-1.732c-.918-.825-2.157-1.332-3.519-1.332-1.602 0-3.035.702-3.974 1.798-1.483-1.113-3.601-1.115-5.086 0-.94-1.096-2.372-1.798-3.974-1.798-1.362 0-2.601.507-3.52 1.332H.734c-.405 0-.733.31-.733.694 0 .384.328.695.732.695h.653c-.334.644-.522 1.368-.522 2.134 0 2.676 2.297 4.854 5.12 4.854s5.12-2.178 5.12-4.854c0-.654-.138-1.277-.385-1.847 1.004-.875 2.559-.876 3.565 0-.248.57-.385 1.193-.385 1.847 0 2.676 2.296 4.854 5.12 4.854 2.822 0 5.12-2.178 5.12-4.854 0-.766-.189-1.49-.523-2.134h.653c.404 0 .732-.311.732-.695 0-.383-.328-.694-.732-.694zM5.983 16.653c-2.016 0-3.655-1.555-3.655-3.465 0-1.911 1.64-3.466 3.655-3.466 2.015 0 3.655 1.555 3.655 3.466 0 1.91-1.64 3.465-3.655 3.465zm13.034 0c-2.015 0-3.655-1.555-3.655-3.465 0-1.911 1.64-3.466 3.655-3.466 2.016 0 3.655 1.555 3.655 3.466 0 1.91-1.64 3.465-3.655 3.465z"
              transform="translate(-258 -657) translate(0 646) translate(258 3)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
