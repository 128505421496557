import React from "react";
import { View } from "../components/Themed";
import { ThemeContext } from "../context/ThemeContext";
import { AppState } from "../types/storesTypes";
import { StyleSheet } from "react-native";
import { Switch } from "react-native-switch";
import { VLabColors, LabeledObject } from "../types/appTypes";
import { DARK_THEME, LANGUAGE } from "../constants/dictionary";
import LanguageItem from "./LanguageItem";
import { LanguagesEnum } from "../types/appEnums";
import useScale, { Scales } from "../hooks/useScale";
import CardRow from "./CardRow";
import { BOLD_FONT, FONT_SIZE_SETTINGS } from "../constants/mainStyles";

type PreferencesCardProps = {
  appState: AppState;
};

const PreferencesCard = ({ appState }: PreferencesCardProps) => {
  const { darkMode, toggle, colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { selectedLanguage, languages, user } = appState;

  return (
    <>
      {user.admin && (
        <CardRow
          title={DARK_THEME[selectedLanguage]}
          value={
            <Switch
              value={darkMode}
              onValueChange={toggle}
              circleSize={scaleFunctions.moderateScale(15, 0.2)}
              circleBorderWidth={0}
              backgroundActive={colors.tint}
              backgroundInactive={colors.cardTitleBackgroundColor}
              circleActiveColor={colors.text}
              circleInActiveColor={colors.text}
              changeValueImmediately={true}
              renderActiveText={false}
              renderInActiveText={false}
            />
          }
        />
      )}

      <CardRow
        title={LANGUAGE[selectedLanguage]}
        value={
          <View
            style={[
              styles(colors, scaleFunctions).langContainer,
              styles(colors, scaleFunctions).preference,
              styles(colors, scaleFunctions).centered,
              { backgroundColor: colors.cardRowBackgroundColor },
            ]}
          >
            {languages.map((item: LabeledObject) => (
              <LanguageItem
                key={item.id}
                id={item.id as LanguagesEnum}
                isSelected={selectedLanguage === item.id}
              />
            ))}
          </View>
        }
      />
    </>
  );
};

export default PreferencesCard;

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    container: {
      flex: 1,
    },
    langContainer: {
      flex: 1,
      maxWidth: moderateScale(100, 0.2),
    },
    preference: {
      flexDirection: "row",
    },
    centered: {
      alignItems: "center",
      justifyContent: "space-around",
    },
    title: {
      fontSize: moderateScale(...FONT_SIZE_SETTINGS),
      fontWeight: "bold",
      fontFamily: BOLD_FONT,
      color: colors.text,
    },
    divider: { backgroundColor: colors.text },
  });
