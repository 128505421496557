import React from "react";
import { Avatar } from "react-native-elements";
import { View, Text } from "../components/Themed";
import { ThemeContext } from "../context/ThemeContext";
import { AppState } from "../types/storesTypes";
import { StyleSheet } from "react-native";
import { VLabColors } from "../types/appTypes";
import useScale, { Scales } from "../hooks/useScale";
import CollapsibleView from "./CollapsibleView";
import CollapsibleViewTitle from "./CollapsibleViewTitle";
import { BOLD_FONT, FONT_SIZE_SETTINGS } from "../constants/mainStyles";

type ProfileCardProps = {
  appState: AppState;
};

const ProfileCard = ({ appState }: ProfileCardProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { user } = appState;

  return (
    <CollapsibleView
      title={
        <CollapsibleViewTitle
          text={"Account"}
          icon="account-circle"
          type="material"
        />
      }
      expanded={true}
      noArrow={true}
      initExpanded={true}
      style={styles(colors, scaleFunctions).collapsibleViewWrapper}
    >
      <View style={styles(colors, scaleFunctions).centered}>
        <Avatar
          size={76}
          rounded
          source={require("../../assets/images/Luxottica.png")}
          containerStyle={styles(colors, scaleFunctions).avatarBorders}
        />
        <Text
          style={[
            styles(colors, scaleFunctions).text,
            styles(colors, scaleFunctions).title,
          ]}
        >
          {`${user.firstName} ${user.lastName}`}
        </Text>
        <Text style={styles(colors, scaleFunctions).text}>{user.email}</Text>
      </View>
    </CollapsibleView>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    collapsibleViewWrapper: {
      backgroundColor: colors.cardTitleBackgroundColor,
      borderWidth: 0,
    },
    text: {
      fontSize: moderateScale(...FONT_SIZE_SETTINGS),
      color: colors.text,
      marginVertical: moderateScale(3, 0.2),
      justifyContent: "center",
    },
    title: {
      fontFamily: BOLD_FONT,
      fontWeight: "bold",
    },
    divider: { backgroundColor: colors.text },
    centered: {
      alignItems: "center",
      justifyContent: "space-around",
      marginVertical: moderateScale(10, 0.2),
    },
    avatarBorders: { marginVertical: moderateScale(10, 0.2) },
  });

export default ProfileCard;
