import React from "react";
import Svg, { Polyline, PolylineProps, SvgProps } from "react-native-svg";

export type ArrowProps = {
  size?: number;
  thickness?: number;
  color?: string;
  rounded?: boolean;
  svgProps?: SvgProps;
  polylineProps?: PolylineProps;
};

export default ({
  size: s = 24,
  thickness = s / 16,
  color = "black",
  rounded = false,
  svgProps = {},
  polylineProps = {},
}: ArrowProps) => {
  const roundedCorners = rounded
    ? // eslint-disable-next-line @typescript-eslint/prefer-as-const
      { strokeLinecap: "round" as "round", strokeLinejoin: "round" as "round" }
    : {};
  return (
    <Svg width={s} height={s} {...svgProps}>
      <Polyline
        points={`${s / 6} ${s / 3} ${s / 2} ${s / 1.5} ${s / 1.2} ${s / 3}`}
        stroke={color}
        strokeWidth={`${thickness}`}
        fill="none"
        {...roundedCorners}
        {...polylineProps}
      />
    </Svg>
  );
};
