import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import * as React from "react";
import {
  InfoLabTabParamList,
  InfoParamList,
  LensesParamList,
  PerformanceParamList,
} from "../types/navigationTypes";
import { ThemeContext } from "../context/ThemeContext";
import {
  headerSettings,
  PERFORMANCE,
  LENSES,
  LENSES_SCREEN,
  PERFORMANCE_SCREEN,
  INFO,
  INFO_SCREEN,
} from "../constants/navigationConstants";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import LensesSvg from "../components/svgs/LensesSvg";
import PerformanceSvg from "../components/svgs/PerformanceSvg";
import useScale from "../hooks/useScale";
import useVLabAppState from "../hooks/useVLabAppState";
import {
  PERFORMANCE_TAB_LABEL,
  LENSES_SCREEN_LABEL,
  INFO_TAB_LABEL,
} from "../constants/dictionary";
import TabButton from "./TabButton";
import { MEDIUM_FONT } from "../constants/mainStyles";
import LensesScreen from "../screens/LensesScreen";
import OverviewScreen from "../screens/OverviewScreen";
import InfoScreen from "../screens/InfoScreen";
import InfoSvg from "../components/svgs/InfoSvg";

const InfoLabTabBottomTab = createBottomTabNavigator<InfoLabTabParamList>();

export default function InfoLabTabNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { moderateScale } = scaleFunctions;
  const appState = useVLabAppState();
  const { bottom } = useSafeAreaInsets();

  return (
    <InfoLabTabBottomTab.Navigator
      initialRouteName={INFO}
      tabBarOptions={{
        activeTintColor: colors.tabIconSelected,
        inactiveTintColor: colors.bottomNavigationDefaultColor,
        inactiveBackgroundColor: colors.bottomNavigationDefaultBackground,
        adaptive: true,
        labelStyle: {
          fontFamily: MEDIUM_FONT,
        },
        tabStyle: {
          padding: moderateScale(3, 0.2),
          paddingBottom: moderateScale(7, 0.2),
        },
        style: {
          height: moderateScale(50, 0.2) + bottom,
          backgroundColor: colors.bottomNavigationDefaultBackground,
        },
        safeAreaInsets: { bottom: bottom ? bottom - 10 : bottom },
      }}
    >
      <InfoLabTabBottomTab.Screen
        name={INFO}
        component={InfoNavigator}
        options={{
          tabBarButton: (props) => <TabButton {...props} />,
          tabBarLabel: appState && INFO_TAB_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <InfoSvg color={color} />,
        }}
      />
      <InfoLabTabBottomTab.Screen
        name={PERFORMANCE}
        component={PerformanceNavigator}
        options={{
          tabBarButton: (props) => <TabButton {...props} />,
          tabBarLabel:
            appState && PERFORMANCE_TAB_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <PerformanceSvg color={color} />,
        }}
      />
      <InfoLabTabBottomTab.Screen
        name={LENSES}
        component={LensesNavigator}
        options={{
          tabBarButton: (props) => <TabButton {...props} />,
          tabBarLabel:
            appState && LENSES_SCREEN_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <LensesSvg color={color} />,
        }}
      />
    </InfoLabTabBottomTab.Navigator>
  );
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const InfoStack = createStackNavigator<InfoParamList>();

function InfoNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <InfoStack.Navigator screenOptions={{ headerShown: true }}>
      <InfoStack.Screen
        name={INFO_SCREEN}
        component={InfoScreen}
        options={{
          ...headerSettings(colors, scaleFunctions),
        }}
      />
    </InfoStack.Navigator>
  );
}
const PerformanceStack = createStackNavigator<PerformanceParamList>();

function PerformanceNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <PerformanceStack.Navigator screenOptions={{ headerShown: true }}>
      <PerformanceStack.Screen
        name={PERFORMANCE_SCREEN}
        component={OverviewScreen}
        options={{
          ...headerSettings(colors, scaleFunctions),
        }}
      />
    </PerformanceStack.Navigator>
  );
}

const LensesStack = createStackNavigator<LensesParamList>();

function LensesNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <LensesStack.Navigator screenOptions={{ headerShown: true }}>
      <LensesStack.Screen
        name={LENSES_SCREEN}
        component={LensesScreen}
        options={{ ...headerSettings(colors, scaleFunctions) }}
      />
    </LensesStack.Navigator>
  );
}
