import { useNavigation } from "@react-navigation/native";
import { HeaderBackButton } from "@react-navigation/stack";
import React, { useState } from "react";
import { ScrollView } from "react-native";
import HeaderBackIcon from "../HeaderBackIcon";
import { GridImageContainer } from "./gridImageContainer";
import { ImageViewer } from "./ImageViewer";
import { Image } from "react-native-elements";
import useScale from "../../hooks/useScale";

type LabImageProps = {
  images: string[];
  setImgIsPressed: (isOpen: boolean) => void;
};

export const ImageContainer = ({ images, setImgIsPressed }: LabImageProps) => {
  const navigation = useNavigation();
  const [modalVisible, setModalVisible] = useState(false);
  const [imageIndex, setimageIndex] = useState(0);
  const { moderateScale } = useScale();
  const onPress = modalVisible ? setModalVisible : setImgIsPressed;

  navigation.setOptions({
    headerLeft: () =>
      navigation.canGoBack() && (
        <HeaderBackButton
          backImage={({ tintColor }) => (
            <HeaderBackIcon tintColor={tintColor} />
          )}
          onPress={() => {
            onPress(false);
          }}
        />
      ),
  });

  return (
    <>
      <ScrollView>
        <Image
          source={{ uri: images[0] }}
          style={{ width: "100%", height: moderateScale(210, 0.3) }}
          onPress={() => {
            setModalVisible(true);
            setimageIndex(0);
          }}
        />
        <GridImageContainer
          images={images.slice(1)}
          setModalVisible={setModalVisible}
          setimageIndex={setimageIndex}
        />
      </ScrollView>
      {modalVisible && <ImageViewer imageIndex={imageIndex} images={images} />}
    </>
  );
};
