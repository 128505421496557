export const formatDate = (date: Date, language: string, withHour = true) => {
  const fullDate = date.toLocaleString(language, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
  const hours = withHour
    ? date.toLocaleTimeString(language, {
        hour: "2-digit",
        minute: "2-digit",
      })
    : "";
  return `${fullDate} ${hours}`.trim();
};
