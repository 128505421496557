import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import * as React from "react";
import { ThemeContext } from "../context/ThemeContext";
import useScale from "../hooks/useScale";
import { InternalTabParamList } from "../types/navigationTypes";
import { MEDIUM_FONT } from "../constants/mainStyles";
import OverviewScreen from "../screens/OverviewScreen";
import LensesScreen from "../screens/LensesScreen";
import {
  PERFORMANCE,
  PERFORMANCE_SCREEN,
  headerSettings,
  LENSES,
  LENSES_SCREEN,
} from "../constants/navigationConstants";
import useVLabAppState from "../hooks/useVLabAppState";
import {
  PERFORMANCE_TAB_LABEL,
  LENSES_SCREEN_LABEL,
} from "../constants/dictionary";
import {
  PerformanceParamList,
  LensesParamList,
} from "../types/navigationTypes";
import { createStackNavigator } from "@react-navigation/stack";
import PerformanceSvg from "../components/svgs/PerformanceSvg";
import LensesSvg from "../components/svgs/LensesSvg";

const InternalBottomTab = createBottomTabNavigator<InternalTabParamList>();

export default function InternalBotttomTabNavigation() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  const { moderateScale } = scaleFunctions;
  const appState = useVLabAppState();

  return (
    <InternalBottomTab.Navigator
      initialRouteName={PERFORMANCE}
      tabBarOptions={{
        activeTintColor: colors.tabIconSelected,
        inactiveTintColor: colors.bottomNavigationDefaultColor,
        inactiveBackgroundColor:
          colors.internalBottomTabInactiveBackgroundColor,
        adaptive: true,
        labelStyle: {
          fontFamily: MEDIUM_FONT,
        },
        tabStyle: {
          padding: moderateScale(3, 0.2),
          paddingBottom: moderateScale(7, 0.2),
        },
        style: {
          height: moderateScale(50, 0.2),
          backgroundColor: colors.internalBottomNavigationDefaultBackground,
          paddingBottom: 0,
          borderTopWidth: 0,
        },
      }}
    >
      <InternalBottomTab.Screen
        name={PERFORMANCE}
        component={PerformanceNavigator}
        options={{
          tabBarLabel:
            appState && PERFORMANCE_TAB_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <PerformanceSvg color={color} />,
        }}
      />
      <InternalBottomTab.Screen
        name={LENSES}
        component={LensesNavigator}
        options={{
          tabBarLabel:
            appState && LENSES_SCREEN_LABEL[appState.selectedLanguage],
          tabBarIcon: ({ color }) => <LensesSvg color={color} />,
        }}
      />
    </InternalBottomTab.Navigator>
  );
}

const PerformanceStack = createStackNavigator<PerformanceParamList>();

function PerformanceNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  return (
    <PerformanceStack.Navigator>
      <PerformanceStack.Screen
        name={PERFORMANCE_SCREEN}
        component={OverviewScreen}
        options={{
          ...headerSettings(colors, scaleFunctions),
        }}
      />
    </PerformanceStack.Navigator>
  );
}

const LensesStack = createStackNavigator<LensesParamList>();

function LensesNavigator() {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();
  return (
    <LensesStack.Navigator>
      <LensesStack.Screen
        name={LENSES_SCREEN}
        component={LensesScreen}
        options={{ ...headerSettings(colors, scaleFunctions) }}
      />
    </LensesStack.Navigator>
  );
}
