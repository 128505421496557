import { AppState } from "../types/storesTypes";
import appStore from "../stores/appStore";
import useGlobalState from "./useGlobalState";

const useVLabAppState = (): AppState | undefined => {
  const appState = useGlobalState(appStore);
  return appState;
};

export default useVLabAppState;
