import React from "react";
import { FlatList, View, StyleSheet } from "react-native";
import useScale, { Scales } from "../../hooks/useScale";
import { Image } from "react-native-elements";

type GridImageContainerProps = {
  images: string[];
  setModalVisible: (setModalVisible: boolean) => void;
  setimageIndex: (index: number) => void;
};

export const GridImageContainer = ({
  images,
  setModalVisible,
  setimageIndex,
}: GridImageContainerProps) => {
  const scaleFunctions = useScale();

  return (
    <>
      <FlatList
        data={images}
        renderItem={({ item, index }) => (
          <View style={{ flex: 1, flexDirection: "column", margin: 1 }}>
            <Image
              style={styles(scaleFunctions).imageThumbnail}
              source={{ uri: item }}
              onPress={() => {
                setModalVisible(true);
                setimageIndex(index + 1);
              }}
            />
          </View>
        )}
        numColumns={2}
        keyExtractor={(item) => item}
      />
    </>
  );
};

const styles = ({ moderateScale }: Scales) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
    },
    imageThumbnail: {
      justifyContent: "center",
      alignItems: "center",
      minHeight: moderateScale(120, 0.2),
    },
    centeredView: {
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      marginTop: 22,
    },
    modalView: {
      margin: 20,
      backgroundColor: "white",
      borderRadius: 20,
      padding: 35,
      alignItems: "center",
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 2,
      },
      shadowOpacity: 0.25,
      shadowRadius: 4,
      elevation: 5,
    },
    modalText: {
      marginBottom: 15,
      textAlign: "center",
    },
  });
};
