import React from "react";
import { ListItem, Icon } from "react-native-elements";
import useScale, { Scales } from "../hooks/useScale";
import { VLabColors } from "../types/appTypes";
import { StyleSheet } from "react-native";
import { ThemeContext } from "../context/ThemeContext";
import { FONT_SIZE_SETTINGS, MEDIUM_FONT } from "../constants/mainStyles";

type CollapsibleViewTitleProps = {
  icon: string;
  type: string;
  text: string;
};

const CollapsibleViewTitle: React.FC<CollapsibleViewTitleProps> = ({
  icon,
  type,
  text,
}) => {
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  const iconSize = scaleFunctions.moderateScale(18, 0.2);

  return (
    <ListItem
      containerStyle={[
        styles(colors, scaleFunctions).collapsibleTitleContainer,
      ]}
    >
      <Icon name={icon} type={type} size={iconSize} color={colors.text} />
      <ListItem.Title
        style={styles(colors, scaleFunctions).collapsibleTitleText}
      >
        {text}
      </ListItem.Title>
    </ListItem>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    collapsibleTitleText: {
      fontSize: moderateScale(...FONT_SIZE_SETTINGS),
      fontFamily: MEDIUM_FONT,
      color: colors.text,
    },
    collapsibleTitleContainer: {
      height: moderateScale(40, 0.2),
      backgroundColor: "transparent",
      borderWidth: 0,
      shadowOpacity: 0,
    },
  });

export default CollapsibleViewTitle;
