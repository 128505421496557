import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="18" height="18" viewBox="0 0 12 16">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <Path
              d="M0 0L18 0 18 18 0 18z"
              transform="translate(-174 -699) translate(0 690) translate(171 8)"
            />
            <path
              fill={color}
              fill-rule="nonzero"
              d="M6 12h6v1.5H6V12zm0-3h6v1.5H6V9zm4.5-7.5h-6C3.675 1.5 3 2.175 3 3v12c0 .825.667 1.5 1.492 1.5H13.5c.825 0 1.5-.675 1.5-1.5V6l-4.5-4.5zm3 13.5h-9V3h5.25v3.75h3.75V15z"
              transform="translate(-174 -699) translate(0 690) translate(171 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
