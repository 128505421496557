import * as React from "react";
import Svg, { Path } from "react-native-svg";

export default ({ color }: { color: string }) => (
  <Svg width="18" height="18" viewBox="0 0 15 12">
    <g fill="none" fill-rule="evenodd">
      <g>
        <g>
          <g>
            <Path
              d="M0 0L18 0 18 18 0 18z"
              transform="translate(-52 -701) translate(0 690) translate(51 8)"
            />
            <Path
              fill={color}
              fill-rule="nonzero"
              d="M3 7.875c-.623 0-1.125.502-1.125 1.125S2.377 10.125 3 10.125 4.125 9.623 4.125 9 3.623 7.875 3 7.875zm0-4.5c-.623 0-1.125.502-1.125 1.125 0 .622.502 1.125 1.125 1.125S4.125 5.122 4.125 4.5c0-.623-.502-1.125-1.125-1.125zm0 9c-.623 0-1.125.51-1.125 1.125s.51 1.125 1.125 1.125 1.125-.51 1.125-1.125S3.623 12.375 3 12.375zm2.25 1.875h10.5v-1.5H5.25v1.5zm0-4.5h10.5v-1.5H5.25v1.5zm0-6v1.5h10.5v-1.5H5.25z"
              transform="translate(-52 -701) translate(0 690) translate(51 8)"
            />
          </g>
        </g>
      </g>
    </g>
  </Svg>
);
