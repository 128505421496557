import * as React from "react";
import { ImageURISource } from "react-native";
import { Avatar } from "react-native-elements";
import { StyleSheet } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import appStore from "../stores/appStore";
import { LanguagesEnum } from "../types/appEnums";
import useScale from "../hooks/useScale";

type LanguageItemProps = {
  id: LanguagesEnum;
  isSelected: boolean;
};

const FLAGS: { [key in LanguagesEnum]: ImageURISource } = {
  it: require(`../../assets/images/flags/it.png`),
  en: require(`../../assets/images/flags/en.png`),
  fr: require(`../../assets/images/flags/fr.png`),
};

const LanguageItem = ({ id, isSelected }: LanguageItemProps) => {
  const scaleFunctions = useScale();

  const _onPress = (languageId: LanguagesEnum) => {
    appStore.changeLanguage(languageId);
  };

  return (
    <TouchableOpacity testID={"LanguageItem"} onPress={() => _onPress(id)}>
      <Avatar
        size={scaleFunctions.moderateScale(20, 0.2)}
        source={FLAGS[id]}
        imageProps={{ style: !isSelected && styles.unselectedImage }}
      />
    </TouchableOpacity>
  );
};

export default LanguageItem;

const styles = StyleSheet.create({
  unselectedImage: {
    opacity: 0.3,
  },
});
