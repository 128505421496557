import React from "react";
import { View, StyleSheet } from "react-native";
import { TIME_FILTERS } from "../constants/appBaseConstants";
import appStore from "../stores/appStore";
import { ApiData } from "../types/apiTypes";
import { AppState } from "../types/storesTypes";
import FilterComponent from "./FilterComponent";
import TimeBar from "./TimeBar";
import { ThemeContext } from "../context/ThemeContext";
import { VLabColors } from "../types/appTypes";

type FiltersAndTimeBarProps = {
  apiData?: ApiData;
  appState: AppState;
};

const FiltersAndTimeBar = ({ appState, apiData }: FiltersAndTimeBarProps) => {
  const { colors } = React.useContext(ThemeContext);

  return (
    <View style={styles(colors).container}>
      <FilterComponent
        filters={TIME_FILTERS}
        language={appState.selectedLanguage}
        selectedFilter={appState.selectedTimeFilter}
        changeFilter={appStore.changeTimeFilter as (filterId: string) => void}
      />
      <TimeBar
        appState={appState}
        timeData={apiData?.time}
        days={apiData?.timeWeek.days}
        timeSpanKey={appState.selectedTimeFilter}
      />
    </View>
  );
};

const styles = (colors: VLabColors) =>
  StyleSheet.create({
    container: {
      backgroundColor: colors.navigationHeaderColor,
    },
  });

export default FiltersAndTimeBar;
