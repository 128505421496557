import { StackHeaderOptions } from "@react-navigation/stack/lib/typescript/src/types";
import { Scales } from "../hooks/useScale";
import { VLabColors } from "../types/appTypes";
import { HEADER_FONT_SIZE, BASE_FONT } from "./mainStyles";

export const ROOT = "Root";
export const LENSES = "Lenses";
export const REPORT = "Report";
export const LENSES_CHANNEL_STACK = "LensesChannelStack";
export const LENSES_SCREEN = "LensesScreen";
export const REPORT_SCREEN = "ReportScreen";
export const NOT_FOUND = "NotFound";
export const OVERVIEW = "Overview";
export const OVERVIEW_SCREEN = "OverviewScreen";
export const INFO = "Info";
export const PERFORMANCE = "Performance";
export const SETTINGS = "Settings";
export const SETTINGS_SCREEN = "SettingsScreen";
export const INFO_SCREEN = "InfoScreen";
export const PERFORMANCE_SCREEN = "PerformanceScreen";
export const AGGREGATE_LIST_STACK = "AggregateListStack";
export const INFO_PAGE_STACK = "InfoPageStack";

export const headerSettings = (
  colors: VLabColors,
  { moderateScale }: Scales,
  bottom?: number
) => {
  return {
    headerStyle: {
      height: 55,
      backgroundColor: colors.navigationHeaderColor,
    },
    headerStatusBarHeight: 0,
    cardStyle: {
      paddingBottom: bottom,
    },
    headerTitleStyle: {
      fontFamily: BASE_FONT,
      fontSize: moderateScale(...HEADER_FONT_SIZE),
      textOverflow: "initial",
      whiteSpace: "initial",
    },
    headerTitleAlign: "center",
    headerBackTitleStyle: {
      fontSize: moderateScale(12, 0.2),
    },
  } as StackHeaderOptions;
};
