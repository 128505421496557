import React from "react";
import { AxisData } from "../../types/apiTypes";

import {
  VictoryBar,
  VictoryChart,
  VictoryAxis,
  VictoryGroup,
} from "../../libs/Victory";
import { ThemeContext } from "../../context/ThemeContext";
import useLayout from "../../hooks/useLayout";
import useScale from "../../hooks/useScale";
import { calculateWidthChart } from "../../utils/screenUtils";
import { Platform } from "react-native";
import { formatNumber } from "../../utils/formatNumber";
import { LanguagesEnum } from "../../types/appEnums";

type BarChartProps = {
  numberOfLabels: number;
  blueBarData: Array<AxisData>;
  greyBarData: Array<AxisData>;
  domainValue?: number;
  stepValue?: number;
  language: LanguagesEnum;
};

const BarChart = ({
  numberOfLabels,
  blueBarData,
  greyBarData,
  domainValue,
  stepValue,
  language,
}: BarChartProps) => {
  const { colors } = React.useContext(ThemeContext);
  const { width } = useLayout();
  const { moderateScale } = useScale();

  const barColors = [colors.orderedText, colors.shippedText];
  const axesSvg = {
    fontSize: 10,
    fill: colors.shippedText,
    fontFamily: "Arial",
  };
  const animation = {
    duration: 1000,
    onLoad: { duration: 500 },
  };

  const widthChart = calculateWidthChart(width);
  const barWidth = widthChart / 9 / numberOfLabels;
  const viewBoxWidth =
    Platform.OS === "web" && width >= 1600 ? widthChart * 1.6 : widthChart;

  const arrY = (sValue: number, dValue: number) => {
    const arr = [];
    const arrLenght = dValue / sValue;

    for (let step = 1; step <= arrLenght; step++) {
      arr.push(step * sValue);
    }
    return arr;
  };

  return (
    <svg
      viewBox={`0 0 ${viewBoxWidth} 300`}
      width={width}
      height={moderateScale(210, 0.3)}
      style={{ paddingTop: 15 }}
    >
      <VictoryChart
        domainPadding={{
          x: numberOfLabels > 4 ? 40 : 120,
          y: 5,
        }}
        padding={{
          top: 10,
          right: 10,
          left: 40,
          bottom: 40,
        }}
        width={widthChart}
        style={{
          parent: {
            height: "100%",
            backgroundColor: colors.background,
          },
        }}
        height={300}
        standalone={false}
      >
        <VictoryAxis
          style={{ tickLabels: axesSvg, axis: { stroke: "grey" } }}
        />
        {domainValue && stepValue && (
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            tickCount={domainValue && stepValue ? domainValue / stepValue : 5}
            tickValues={arrY(stepValue!, domainValue!)}
            tickFormat={(t) => formatNumber(t, language)}
            style={{
              grid: { stroke: "grey" },
              tickLabels: axesSvg,
              axis: { stroke: "transparent" },
            }}
          />
        )}
        {!domainValue && !stepValue && (
          <VictoryAxis
            dependentAxis
            crossAxis={false}
            tickCount={5}
            tickFormat={(t) => formatNumber(t, language)}
            style={{
              grid: { stroke: "grey" },
              tickLabels: axesSvg,
              axis: { stroke: "transparent" },
            }}
          />
        )}
        <VictoryGroup
          offset={moderateScale(barWidth + 5, 0.2)}
          colorScale={barColors}
        >
          {domainValue && (
            <VictoryBar
              barWidth={moderateScale(barWidth, 0.2)}
              barRatio={0.3}
              animate={animation}
              data={blueBarData}
              maxDomain={{ y: domainValue }}
              minDomain={{ y: domainValue }}
              domain={{ y: [0, domainValue] }}
            />
          )}
          {!domainValue && (
            <VictoryBar
              barWidth={moderateScale(barWidth, 0.2)}
              barRatio={0.3}
              animate={animation}
              data={blueBarData}
            />
          )}
          {domainValue && (
            <VictoryBar
              barWidth={moderateScale(barWidth, 0.2)}
              barRatio={0.3}
              animate={animation}
              data={greyBarData}
              maxDomain={{ y: domainValue }}
              minDomain={{ y: domainValue }}
              domain={{ y: [0, domainValue] }}
            />
          )}
          {!domainValue && (
            <VictoryBar
              barWidth={moderateScale(barWidth, 0.2)}
              barRatio={0.3}
              animate={animation}
              data={greyBarData}
            />
          )}
        </VictoryGroup>
      </VictoryChart>
    </svg>
  );
};

export default BarChart;
