import React from "react";
import { View, Text, StyleSheet } from "react-native";
import {
  INFO_LAB,
  ACTIVITY_SUMMARY_LABEL as ACTIVITY_LABEL,
  YDT_LABEL,
  FY_PY_LABEL,
  FY_2PY_LABEL,
  LAB_INFO_LABELS,
  JOBS_LABEL,
} from "../../constants/dictionary";
import { GlobalStatesContext } from "../../context/GlobalStatesContext";
import { InfoLabData } from "../../types/apiTypes";
import { Label, VLabColors } from "../../types/appTypes";
import { ThemeContext } from "../../context/ThemeContext";
import useScale, { Scales } from "../../hooks/useScale";
import { ScrollView } from "react-native-gesture-handler";
import {
  BOLD_FONT,
  TIME_BAR_FONT_SIZE,
  TIME_BAR_INFO_FONT_SIZE,
  VIEW_STYLES,
} from "../../constants/mainStyles";
import {
  NO_DATA_NUMBER,
  SQUARE_METERS_LABEL,
  NOT_AVIABLE_LABEL,
  JAPAN_LAB_CODE,
} from "../../constants/appBaseConstants";
import { formatNumber } from "../../utils/formatNumber";
import { formatDate } from "../../utils/formatDate";

type InfoLabProps = {
  infoLabData: InfoLabData;
};

type LabInfoData = {
  title: Label;
  values: {
    [name: string]: string | number | null;
  };
};

export const LabInfo = ({ infoLabData }: InfoLabProps) => {
  const { appState } = React.useContext(GlobalStatesContext);
  const { colors } = React.useContext(ThemeContext);
  const scaleFunctions = useScale();

  const objectInfoLab: LabInfoData[] = [
    {
      title: INFO_LAB,
      values: {
        kingOfLab: infoLabData.kingOfLab,
        processInTheLab: infoLabData.processInTheLab,
        location: infoLabData.location,
        openingYear: infoLabData.openingYear,
        labSurface: infoLabData.labSurface,
      },
    },
  ];

  const objectActivitySyintesis: LabInfoData[] = [
    {
      title: ACTIVITY_LABEL,
      values: {
        lastUpdateDate: infoLabData.lastUpdateDate
          ? formatDate(
              new Date(infoLabData.lastUpdateDate),
              appState.selectedLanguage,
              false
            )
              .split("/")
              .join(".")
          : "",
        headCount: infoLabData.headCount,
      },
    },
  ];

  const objectJobShippedCy: LabInfoData[] = [
    {
      title: YDT_LABEL,
      values: {
        totalJobShippedCY: formatNumber(
          infoLabData.shippedTotalCY,
          appState.selectedLanguage
        ),
        jobAShippedCY: formatNumber(
          infoLabData.shippedACY,
          appState.selectedLanguage
        ),
        jobBCShippedCY:
          infoLabData.lab === JAPAN_LAB_CODE && infoLabData.shippedBCCY === 0
            ? NOT_AVIABLE_LABEL
            : formatNumber(infoLabData.shippedBCCY, appState.selectedLanguage),
      },
    },
  ];
  const objectJobShippedOnePy: LabInfoData[] = [
    {
      title: FY_PY_LABEL,
      values: {
        totalJobShippedPY: formatNumber(
          infoLabData.shippedTotal1PY,
          appState.selectedLanguage
        ),
        jobAShippedPY: formatNumber(
          infoLabData.shippedA1PY,
          appState.selectedLanguage
        ),
        jobBCShippedPY:
          infoLabData.lab === JAPAN_LAB_CODE && infoLabData.shippedBC1PY === 0
            ? NOT_AVIABLE_LABEL
            : formatNumber(infoLabData.shippedBC1PY, appState.selectedLanguage),
      },
    },
  ];
  const objectJobShippedTwoPy: LabInfoData[] = [
    {
      title: FY_2PY_LABEL,
      values: {
        totalJobShipped2PY:
          infoLabData.lab === JAPAN_LAB_CODE &&
          infoLabData.shippedTotal2PY === 0
            ? NOT_AVIABLE_LABEL
            : formatNumber(
                infoLabData.shippedTotal2PY,
                appState.selectedLanguage
              ),
        jobAShipped2PY:
          infoLabData.lab === JAPAN_LAB_CODE && infoLabData.shippedA2PY === 0
            ? NOT_AVIABLE_LABEL
            : formatNumber(infoLabData.shippedA2PY, appState.selectedLanguage),
        jobBCShipped2PY:
          infoLabData.lab === JAPAN_LAB_CODE && infoLabData.shippedBC2PY === 0
            ? NOT_AVIABLE_LABEL
            : formatNumber(infoLabData.shippedBC2PY, appState.selectedLanguage),
      },
    },
  ];

  const renderSection = (
    objectToRender: LabInfoData[],
    year?: number | null,
    description?: string | null
  ) => {
    return (
      <>
        {objectToRender.map((item: LabInfoData, id) => {
          return (
            <View key={id} style={[styles(colors, scaleFunctions).container]}>
              <View
                key={id}
                style={[
                  VIEW_STYLES.row,
                  styles(colors, scaleFunctions).infoSectionTitle,
                ]}
              >
                <Text style={[styles(colors, scaleFunctions).text]}>
                  {item
                    ? item.title[appState.selectedLanguage]
                    : NO_DATA_NUMBER}
                  {year && year.toString().substr(-2)}
                </Text>
                <Text style={[styles(colors, scaleFunctions).textInfo]}>
                  {description}
                </Text>
              </View>
              {item &&
                Object.entries(item.values).map(([key, element]) => {
                  return (
                    <View
                      key={key}
                      style={[styles(colors, scaleFunctions).infoLabel]}
                    >
                      <View
                        style={[
                          styles(colors, scaleFunctions).infoLabelLeftContainer,
                        ]}
                      >
                        <Text
                          style={[styles(colors, scaleFunctions).infoLabelLeft]}
                        >
                          {LAB_INFO_LABELS[key][appState.selectedLanguage]}
                          {year && year}
                        </Text>
                      </View>

                      <View
                        style={styles(colors, scaleFunctions).infoLabelRight}
                      >
                        <Text
                          style={
                            key === "lastUpdateDate"
                              ? [styles(colors, scaleFunctions).lastUpdateLabel]
                              : [styles(colors, scaleFunctions).text]
                          }
                        >
                          {element ? element : NO_DATA_NUMBER}
                          {key === "labSurface" && (
                            <Text
                              style={
                                styles(colors, scaleFunctions).squareUnitsLabel
                              }
                            >
                              {SQUARE_METERS_LABEL}
                            </Text>
                          )}
                        </Text>
                      </View>
                    </View>
                  );
                })}
            </View>
          );
        })}
      </>
    );
  };

  return (
    <>
      <ScrollView>
        <View style={styles(colors, scaleFunctions).dividerSection}>
          {renderSection(objectInfoLab)}
        </View>
        {renderSection(objectActivitySyintesis)}
        {renderSection(
          objectJobShippedCy,
          infoLabData.cy,
          JOBS_LABEL[appState.selectedLanguage]
        )}
        {renderSection(
          objectJobShippedOnePy,
          infoLabData.onePY,
          JOBS_LABEL[appState.selectedLanguage]
        )}
        {renderSection(
          objectJobShippedTwoPy,
          infoLabData.twoPY,
          JOBS_LABEL[appState.selectedLanguage]
        )}
      </ScrollView>
    </>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) => {
  return StyleSheet.create({
    container: {
      flex: 1,
      paddingHorizontal: moderateScale(15, 0.2),
      paddingVertical: moderateScale(10, 0.2),
    },
    infoSectionTitle: {
      paddingBottom: moderateScale(10, 0.2),
      alignItems: "baseline",
    },
    dividerSection: {
      borderBottomWidth: 1,
      borderBottomColor: colors.dividerSection,
    },
    text: {
      color: "white",
      fontFamily: BOLD_FONT,
      fontWeight: "bold",
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
    },
    textInfo: {
      color: "white",
      fontFamily: BOLD_FONT,
      fontWeight: "bold",
      fontSize: moderateScale(...TIME_BAR_INFO_FONT_SIZE),
      fontStyle: "italic",
    },
    infoLabel: {
      display: "flex",
      flexDirection: "row",
    },
    lastUpdateLabel: {
      color: colors.orderedText,
      fontFamily: BOLD_FONT,
      fontWeight: "bold",
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
    },
    infoLabelLeftContainer: {
      width: "50%",
      paddingHorizontal: moderateScale(8, 0.2),
    },
    infoLabelLeft: {
      display: "flex",
      justifyContent: "flex-end",
      color: colors.infoLabLabelLeft,
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
    },
    infoLabelRight: {
      width: "50%",
      display: "flex",
      justifyContent: "flex-end",
      paddingHorizontal: moderateScale(8, 0.2),
    },
    squareUnitsLabel: {
      color: "white",
      fontFamily: BOLD_FONT,
      fontWeight: "bold",
      fontSize: moderateScale(...TIME_BAR_FONT_SIZE),
      paddingLeft: moderateScale(3, 0.2),
    },
  });
};

export default LabInfo;
