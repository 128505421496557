import React from "react";
import { OverviewData, OverviewItem } from "../../types/apiTypes";
import { VLabNavigationState } from "../../types/storesTypes";
import { View, ScrollView, StyleSheet, TextStyle } from "react-native";
import { Table, Row } from "@deb-95/react-native-table-component";
import { ThemeContext } from "../../context/ThemeContext";
import {
  OVERVIEW_TABLE_FIRST_ROW_HEIGHT,
  OVERVIEW_TABLE_HEAD_HEIGHT,
  TABLE_FONT_SIZE,
  TABLE_HEAD_PADDING,
} from "../../constants/mainStyles";
import { VLabColors } from "../../types/appTypes";
import useScale, { Scales } from "../../hooks/useScale";
import {
  TABLE_HEADER_EMPTY,
  TABLE_HEADER_SERVICE,
} from "../../constants/dictionary";
import { LanguagesEnum } from "../../types/appEnums";
import { useNavigation } from "@react-navigation/native";
import { Icon } from "react-native-elements";
import ClickableRow from "./ClickableRow";
import CellWithImage from "./CellWithImage";
import {
  formatNumber,
  numberWithUnit,
  formatNumberKpiServiceBrazilJapan,
  formatNumberWithRound,
} from "../../utils/formatNumber";
import {
  CELL_PADDING_RIGHT,
  TABLE_HEADER_ELEMENTS,
  ROW_PADDING_LEFT,
  LAST_CELL_PADDING_RIGHT,
  BRAZIL_LAB_CODE,
  JAPAN_LAB_CODE,
} from "../../constants/appBaseConstants";
import { TableText } from "../TableText";
import { LATO_BASE_FONT } from "../../constants/mainStyles";
import { brightenUp } from "../../utils/colorsUtils";

const flexArrHeader = [2.1, 1.5, 1.5, 1, 1];
const flexArr = [2.1, 1.5, 1.5, 1, 0.8, 0.2];

type OverviewTableProps = {
  overviewData: OverviewData;
  selectedLanguage: LanguagesEnum;
  navigationState: VLabNavigationState;
};

export const OverviewTable = ({
  overviewData,
  selectedLanguage,
  navigationState,
}: OverviewTableProps) => {
  const { colors } = React.useContext(ThemeContext);
  const scale = useScale();
  const { navigate } = useNavigation();

  const getTableHead = () => [
    TABLE_HEADER_EMPTY[selectedLanguage],
    ...TABLE_HEADER_ELEMENTS(selectedLanguage).map(
      (overviewHeaderElement: [string, string], index: number) => (
        <View
          key={`head${index}`}
          style={styles(colors, scale).headTextContainer}
        >
          <TableText
            color={index === 0 ? colors.orderedText : colors.text}
            alignment={"right"}
            justifyContent={"flex-start"}
            bgCol={
              index % 2 !== 1
                ? brightenUp(colors.tableRowBgCol)
                : colors.tableRowBgCol
            }
            additionalStyle={[
              index === 3
                ? styles(colors, scale).lastCellPaddingRx
                : styles(colors, scale).cellPaddingRx,
              styles(colors, scale).headTextMarginTop,
            ]}
          >
            {`${overviewHeaderElement[0]
              .charAt(0)
              .toUpperCase()}${overviewHeaderElement[0].slice(1)}`}
            {"\n"}
            {overviewHeaderElement[1] !==
              TABLE_HEADER_SERVICE[selectedLanguage] && (
              <span style={{ fontSize: scale.moderateScale(9, 0.2) }}>
                ({overviewHeaderElement[1]})
              </span>
            )}
          </TableText>
          {overviewHeaderElement[1] ===
            TABLE_HEADER_SERVICE[selectedLanguage] && (
            <TableText
              color={index === 0 ? colors.orderedText : colors.text}
              alignment={"right"}
              justifyContent={"flex-start"}
              bgCol={
                index % 2 !== 1
                  ? brightenUp(colors.tableRowBgCol)
                  : colors.tableRowBgCol
              }
              additionalStyle={[
                styles(colors, scale).headerRowSymbolStyle,
                index === 3
                  ? styles(colors, scale).lastCellPaddingRx
                  : styles(colors, scale).cellPaddingRx,
                styles(colors, scale).headTextMarginBottom,
              ]}
            >
              {`(${overviewHeaderElement[1]})`}
            </TableText>
          )}
        </View>
      )
    ),
    TABLE_HEADER_EMPTY[selectedLanguage],
  ];

  const getTableRow = (
    rowData: OverviewItem,
    textStyle: TextStyle | TextStyle[],
    chevron = true,
    bgColPair = brightenUp(colors.tableRowBgCol),
    bgColOdd = colors.tableRowBgCol
  ) => [
    <CellWithImage
      key={`${rowData.meta.id}0`}
      itemMeta={rowData.meta}
      selectedLanguage={selectedLanguage}
      additionalTextStyle={[textStyle, { textAlign: "left" }]}
    />,
    <TableText
      color={colors.text}
      bgCol={bgColPair}
      key={`${rowData.meta.id}1`}
      additionalStyle={textStyle}
      fontFam={LATO_BASE_FONT}
    >
      {formatNumber(rowData.orders, selectedLanguage)}
    </TableText>,

    <TableText
      key={`${rowData.meta.id}2`}
      additionalStyle={textStyle}
      color={colors.text}
      bgCol={bgColOdd}
      fontFam={LATO_BASE_FONT}
    >
      {formatNumber(rowData.shipments, selectedLanguage)}
    </TableText>,

    <TableText
      key={`${rowData.meta.id}3`}
      additionalStyle={textStyle}
      color={colors.text}
      bgCol={bgColPair}
      fontFam={LATO_BASE_FONT}
    >
      {/*[VLP-349] [VLP-348] label changed from '-' to 'n.a.' when kpi service is null for Brazil and Japan lab only*/}
      {rowData.meta.id === BRAZIL_LAB_CODE || rowData.meta.id === JAPAN_LAB_CODE
        ? formatNumberKpiServiceBrazilJapan(rowData.service, selectedLanguage)
        : formatNumberWithRound(rowData.service, selectedLanguage)}
    </TableText>,

    <TableText
      key={`${rowData.meta.id}4`}
      additionalStyle={textStyle}
      color={colors.text}
      bgCol={bgColOdd}
      fontFam={LATO_BASE_FONT}
    >
      {numberWithUnit(
        formatNumberWithRound(rowData.quality, selectedLanguage),
        "%"
      )}
    </TableText>,

    chevron ? (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: bgColOdd,
        }}
      >
        <Icon
          name={"chevron-thin-right"}
          type={"entypo"}
          size={scale.moderateScale(20, 0.2)}
          color={colors.chevron}
          style={{
            textAlign: "center",
            paddingRight: LAST_CELL_PADDING_RIGHT,
          }}
        />
      </View>
    ) : (
      <View
        style={{
          flex: 1,
          justifyContent: "center",
          backgroundColor: bgColOdd,
        }}
      />
    ),
  ];

  return (
    <Table style={{ flex: 3, backgroundColor: colors.tableRowBgCol }}>
      {/* table header */}
      <Row
        data={getTableHead()}
        flexArr={flexArrHeader}
        style={[
          {
            backgroundColor: colors.tableRowBgCol,
            height: scale.moderateVerticalScale(...OVERVIEW_TABLE_HEAD_HEIGHT),
            borderBottomWidth: 1,
            borderBottomColor: colors.tableBottomBorder,
          },
          styles(colors, scale).row,
        ]}
      />
      {/* table first row */}
      <Row
        data={getTableRow(
          overviewData.table.head,

          [
            styles(colors, scale).bodyText,
            {
              textAlign: "right",
              paddingRight: scale.moderateScale(CELL_PADDING_RIGHT, 0.2),
            },
          ],
          false,
          brightenUp(colors.overviewTableFirstRow),
          colors.overviewTableFirstRow
        )}
        flexArr={flexArr}
        style={[
          styles(colors, scale).row,
          {
            backgroundColor: colors.overviewTableFirstRow,
            height: scale.moderateVerticalScale(
              ...OVERVIEW_TABLE_FIRST_ROW_HEIGHT
            ),
            borderBottomWidth: 1,
            borderBottomColor: colors.tableBottomBorder,
          },
        ]}
      />
      {/* table normal rows */}
      <ScrollView
        contentContainerStyle={{ flex: 1 }}
        showsVerticalScrollIndicator={false}
      >
        {overviewData.table.items.map((item: OverviewItem, idx: number) => (
          <ClickableRow
            key={`bodyRow${idx}`}
            navigationState={navigationState}
            itemMeta={item.meta}
            splitId={overviewData.table.splitMeta.id}
            splitType={item.type} // table.items[0].type
            navigate={navigate}
            datas={getTableRow(item, styles(colors, scale).bodyText)}
            flexArr={flexArr}
          />
        ))}
      </ScrollView>
    </Table>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    headTextContainer: {
      flex: 1,
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "center",
    },
    headTextMarginTop: {
      marginTop: moderateScale(...TABLE_HEAD_PADDING),
    },
    headTextMarginBottom: {
      marginBottom: moderateScale(...TABLE_HEAD_PADDING),
    },
    bodyText: {
      textAlign: "right",
      fontSize: moderateScale(...TABLE_FONT_SIZE),
      paddingRight: moderateScale(CELL_PADDING_RIGHT, 0.2),
    },
    headerRowSymbolStyle: {
      fontSize: moderateScale(9, 0.2),
      paddingRight: moderateScale(CELL_PADDING_RIGHT, 0.2),
    },
    row: {
      paddingLeft: moderateScale(ROW_PADDING_LEFT, 0.2),
    },
    cellPaddingRx: {
      paddingRight: moderateScale(CELL_PADDING_RIGHT, 0.2),
    },
    lastCellPaddingRx: {
      paddingRight: moderateScale(LAST_CELL_PADDING_RIGHT, 0.2),
    },
  });
