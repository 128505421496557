import {
  IS_LOCAL,
  LOCAL_STORAGE_LOGGED_NAME,
  LOCAL_STORAGE_TOKEN_NAME,
} from "../constants/appBaseConstants";

export const removeParamsFromUrl = () => {
  window.history.pushState({}, document.title, "/");
};

export const isLoggedSuccess = (param: string | null): boolean =>
  (param && param === "true") as boolean;

export const getUserLogged = (): [boolean, boolean] => {
  const storageLogged = isLoggedSuccess(
    localStorage.getItem(LOCAL_STORAGE_LOGGED_NAME)
  );
  const urlParams = new URLSearchParams(window.location.search);
  const urlLogged = isLoggedSuccess(urlParams.get("login"));
  const localToken =
    IS_LOCAL() && localStorage.getItem(LOCAL_STORAGE_TOKEN_NAME);
  return [
    !IS_LOCAL() ? urlLogged || storageLogged : Boolean(localToken),
    Boolean(urlLogged),
  ] as [boolean, boolean];
};
