import React, { useState, useEffect, useRef } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  Animated,
  Easing,
  ViewStyle,
  TextStyle,
  StyleProp,
} from "react-native";
import Collapsible, { CollapsibleProps } from "react-native-collapsible";
import ArrowDownIcon, { ArrowProps } from "./svgs/ArrowDownIcon";
import { View, Text, TextProps } from "./Themed";

type CollapsibleViewProps = {
  title: string | React.ReactNode;
  initExpanded?: boolean;
  expanded?: boolean | null;
  unmountOnCollapse?: boolean;
  duration?: number;
  collapsibleProps?: CollapsibleProps;
  collapsibleContainerStyle?: StyleProp<ViewStyle>;
  arrowStyling?: ArrowProps;
  noArrow?: boolean;
  style?: StyleProp<ViewStyle>;
  activeOpacityFeedback?: number;
  titleProps?: TextProps;
  titleStyle?: StyleProp<TextStyle>;
};

const CollapsibleView: React.FC<CollapsibleViewProps> = ({
  children,
  title = "",
  initExpanded = false,
  expanded = null,
  unmountOnCollapse = false,
  duration = 300,
  collapsibleProps = {},
  collapsibleContainerStyle,
  arrowStyling,
  noArrow = false,
  style = {},
  activeOpacityFeedback = 0.3,
  titleProps = {},
  titleStyle = {},
}) => {
  const controlled = expanded === null ? false : true;
  const [show, setShow] = useState(initExpanded);
  const [mounted, setMounted] = useState(initExpanded);

  const rotateAnim = useRef(new Animated.Value(0)).current;

  if (controlled) {
    if (!mounted && expanded) setMounted(true);
  }

  const handleArrowRotate = (open: boolean | null = null) => {
    const _open = open === null ? show : open;
    if (!_open)
      Animated.timing(rotateAnim, {
        toValue: 0,
        duration,
        easing: Easing.ease,
        useNativeDriver: false,
      }).start();
    else {
      Animated.timing(rotateAnim, {
        toValue: rotateAngle,
        duration,
        easing: Easing.ease,
        useNativeDriver: false,
      }).start();
    }
  };

  const handleAnimationEnd = () => {
    if (unmountOnCollapse && !show) setMounted(false);
  };

  const handleToggleShow = () => {
    if (!controlled)
      if (!mounted) {
        if (!show) setMounted(true);
      } else {
        setShow(!show);
      }
  };

  const rowDir = "row";
  const rotateAngle = -90;
  const rotateAnimDeg = rotateAnim.interpolate({
    inputRange: [0, 360],
    outputRange: ["0deg", "360deg"],
  });

  const TitleElement = typeof title === "string" ? <Text>{title}</Text> : title;

  useEffect(() => {
    // this part is to trigger collapsible animation only after he has been fully mounted so animation would
    // not be interrupted.
    if (mounted) {
      setShow(true);
    }
  }, [mounted]);

  useEffect(() => {
    // on mounting set the rotation angle
    rotateAnim.setValue(show ? 0 : rotateAngle);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (mounted) handleArrowRotate(!show);
    if (controlled && show !== expanded) setShow(expanded);
  });

  return (
    <View style={[styles.container, style]}>
      <TouchableOpacity
        onPress={handleToggleShow}
        activeOpacity={activeOpacityFeedback}
        style={[
          {
            flexDirection: rowDir,
            alignItems: "center",
            alignSelf: "stretch",
            justifyContent: "space-between",
          },
          titleStyle,
        ]}
        {...titleProps}
      >
        {TitleElement}
        {noArrow ? null : (
          <Animated.View
            style={{ transform: [{ rotate: rotateAnimDeg }], marginRight: 10 }}
          >
            <ArrowDownIcon {...arrowStyling} />
          </Animated.View>
        )}
      </TouchableOpacity>
      {mounted ? (
        <View style={[{ width: "100%" }, collapsibleContainerStyle]}>
          <Collapsible
            onAnimationEnd={handleAnimationEnd}
            collapsed={!show}
            {...{ duration, ...collapsibleProps }}
          >
            {children}
          </Collapsible>
        </View>
      ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    marginBottom: 10,
    overflow: "hidden",
  },
});

export default CollapsibleView;
