import React, { useContext } from "react";
import { Button, Platform, StyleSheet } from "react-native";
import { RELOAD } from "../constants/dictionary";
import { View, Text } from "../components/Themed";
import { Updates } from "expo";
import { HEADER_FONT_SIZE } from "../constants/mainStyles";
import useScale, { Scales } from "../hooks/useScale";
import { GlobalStatesContext } from "../context/GlobalStatesContext";

const errorScreenStyles = ({ moderateScale, scale }: Scales) =>
  StyleSheet.create({
    container: {
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    text: {
      textAlign: "center",
    },
    lateralPadding: {
      paddingHorizontal: scale(35),
    },
    titleText: {
      fontSize: moderateScale(...HEADER_FONT_SIZE),
      paddingBottom: moderateScale(14, 0.2),
    },
    detailText: {
      fontSize: moderateScale(12, 0.2),
      paddingBottom: moderateScale(12, 0.2),
    },
  });

type ErrorScreenProps = { errorMessage: string; msgDetail: string };

const ErrorScreen: React.FC<ErrorScreenProps> = ({
  errorMessage,
  msgDetail,
}: ErrorScreenProps) => {
  const scaleFunctions = useScale();
  const { appState } = useContext(GlobalStatesContext);
  return (
    <View style={errorScreenStyles(scaleFunctions).container}>
      <Text
        style={[
          errorScreenStyles(scaleFunctions).text,
          errorScreenStyles(scaleFunctions).titleText,
          errorScreenStyles(scaleFunctions).lateralPadding,
        ]}
      >
        {errorMessage}
      </Text>
      <Text
        style={[
          errorScreenStyles(scaleFunctions).text,
          errorScreenStyles(scaleFunctions).detailText,
          errorScreenStyles(scaleFunctions).lateralPadding,
        ]}
      >
        {msgDetail}
      </Text>
      <Button
        title={RELOAD[appState.selectedLanguage]}
        onPress={() => {
          if (Platform.OS !== "web") {
            Updates.reload();
          } else {
            location.replace("/");
          }
        }}
      />
    </View>
  );
};

export default React.memo(ErrorScreen);
