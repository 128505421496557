import React from "react";
import { StyleProp, TextStyle, StyleSheet } from "react-native";
import { NO_DATA_NUMBER } from "../constants/appBaseConstants";
import { BASE_FONT, TABLE_FONT_SIZE } from "../constants/mainStyles";
import useScale, { Scales } from "../hooks/useScale";
import { Text } from "./Themed";
import { View } from "react-native";

type TableTextProps = {
  additionalStyle?: StyleProp<TextStyle>;
  alignment?: "center" | "auto" | "left" | "right" | "justify";
  fontFam?: string;
  fontWeight?:
    | "normal"
    | "bold"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900"
    | undefined;
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | undefined;
  flexGrow?: number;
  testID?: string;
  color?: string;
  bgCol?: string;
  numberOfLines?: number;
  ellipsizeMode?: "head" | "middle" | "tail" | "clip";
};
export const TableText: React.FC<TableTextProps> = ({
  additionalStyle,
  children,
  alignment,
  fontFam = BASE_FONT,
  fontWeight = "normal",
  justifyContent = "center",
  flexGrow = 1,
  color,
  bgCol,
  numberOfLines,
  ellipsizeMode = "clip",
}) => {
  const scaleFunctions = useScale();
  return (
    <View
      style={{
        flexGrow: flexGrow,
        flexShrink: 1,
        width: "100%",
        justifyContent: justifyContent,
        backgroundColor: bgCol,
      }}
    >
      <Text
        style={[
          styles(scaleFunctions).tableText,
          {
            textAlign: alignment,
            fontFamily: fontFam,
            fontWeight: fontWeight,
            color: color,
          },
          additionalStyle,
        ]}
        numberOfLines={numberOfLines}
        ellipsizeMode={ellipsizeMode}
      >
        {children !== undefined ? children : NO_DATA_NUMBER}
      </Text>
    </View>
  );
};

const styles = ({ moderateScale }: Scales) => {
  return StyleSheet.create({
    tableText: {
      fontSize: moderateScale(...TABLE_FONT_SIZE),
    },
  });
};
