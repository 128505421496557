import { VLabNavigationState } from "../types/storesTypes";
import navigationStore from "../stores/navigationStore";
import useGlobalState from "./useGlobalState";

const useVLabNavigationState = (): VLabNavigationState | undefined => {
  const navigationState = useGlobalState(navigationStore);
  return navigationState;
};

export default useVLabNavigationState;
