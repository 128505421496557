import * as React from "react";
import { StyleSheet } from "react-native";
import LensesTable from "./LensesTable";
import { View } from "../Themed";
import { LensesData } from "../../types/apiTypes";
import { AppState } from "../../types/storesTypes";
import useLayout from "../../hooks/useLayout";
import FilterComponent from "../FilterComponent";
import { VIEW_STYLES } from "../../constants/mainStyles";
import { ThemeContext } from "../../context/ThemeContext";
import LoadingView from "../LoadingView";
import { VLabColors } from "../../types/appTypes";
import { TIME_FILTERS } from "../../constants/appBaseConstants";
import appStore from "../../stores/appStore";
import TimeBar from "../TimeBar";

type LensesPageProps = {
  lensesData?: LensesData;
  appState: AppState;
  isLoading: boolean;
};

const LensesPage = ({ appState, lensesData, isLoading }: LensesPageProps) => {
  const { height } = useLayout();
  const { colors } = React.useContext(ThemeContext);

  return (
    <View style={styles(colors).stack}>
      <View style={[styles(colors).container, { height }]}>
        <FilterComponent
          filters={TIME_FILTERS}
          language={appState.selectedLanguage}
          selectedFilter={appState.selectedTimeFilter}
          changeFilter={appStore.changeTimeFilter as (filterId: string) => void}
        />
        <TimeBar
          appState={appState}
          timeData={lensesData?.time}
          days={lensesData?.timeWeek?.days}
          timeSpanKey={appState.selectedTimeFilter}
        />
        <View style={VIEW_STYLES.centeredFlex}>
          {lensesData && (
            <LensesTable appState={appState} lensesData={lensesData.table} />
          )}
        </View>
      </View>
      {isLoading && <LoadingView />}
    </View>
  );
};

const styles = (colors: VLabColors) =>
  StyleSheet.create({
    container: {
      flex: 1,
      justifyContent: "center",
      backgroundColor: colors.navigationHeaderColor,
    },
    stack: {
      flex: 1,
      borderBottomWidth: 2,
      borderBottomColor: colors.tableRowBgCol,
    },
  });

export default LensesPage;
