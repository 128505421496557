import appStore from "../stores/appStore";
import {
  LOCAL_STORAGE_LANGUAGE_NAME,
  LOCAL_STORAGE_LOGGED_NAME,
  LOCAL_STORAGE_THEME_NAME,
  LOCAL_STORAGE_TOKEN_NAME,
} from "../constants/appBaseConstants";
import { AjaxError } from "rxjs/ajax";
import { NOT_FOUND_ERROR, ERROR_SUBTEXT } from "../constants/dictionary";
import AsyncStorage from "@react-native-community/async-storage";

type BaseError = {
  message: string;
};

type RuntimeError = BaseError & {
  error: string;
  path: string;
  status: number;
  timestamp: string;
  trace: string;
};

type BackendError = RuntimeError & BaseError;

export function isError(arg: any): arg is AjaxError {
  return arg.message !== undefined;
}

const handleError = (err: AjaxError) => {
  let errorMessage;
  let msgDetails;
  switch (err.status) {
    case 401:
      AsyncStorage.removeItem(LOCAL_STORAGE_TOKEN_NAME);
      AsyncStorage.removeItem(LOCAL_STORAGE_THEME_NAME);
      AsyncStorage.removeItem(LOCAL_STORAGE_LOGGED_NAME);
      AsyncStorage.removeItem(LOCAL_STORAGE_LANGUAGE_NAME);
      location.reload();
      break;
    case 404:
      errorMessage = NOT_FOUND_ERROR[appStore.getSelectedLanguage()];
      msgDetails = ERROR_SUBTEXT[appStore.getSelectedLanguage()];
      appStore.setError(true, errorMessage, msgDetails);
      break;
    default:
      const response = err.response as BackendError;
      // example: { error: "Internal Server Error", message: "Incorrect jwt" }
      // if response.error is present we put it as errorMessage and the response.message as errorDetail
      // else we only use response.message as errorMessage
      errorMessage = response.error || response.message;
      msgDetails = response.error && response.message;
      appStore.setError(true, errorMessage, msgDetails);
      break;
  }
};

export default handleError;
