import { StatusBar } from "expo-status-bar";
import React, { useState } from "react";
import { View } from "./Themed";
import { StyleSheet } from "react-native";
import Navigation from "../navigation/Navigation";
import VlProgressBar from "./VlProgressBar";
import useMountEffect from "../hooks/useMountEffect";
import {
  IS_LOCAL,
  LOCAL_STORAGE_LOGGED_NAME,
} from "../constants/appBaseConstants";
import { API_ENDPOINT } from "../apis/ApiBaseSettings";
import { AppLoading } from "expo";
import { getNam } from "../apis/SettingsApi";
import ErrorScreen from "../screens/ErrorScreen";
import appStore from "../stores/appStore";
import useLayout from "../hooks/useLayout";
import { LATERAL_DESKTOP_VIEW_PADDING_WIDTH } from "../constants/mainStyles";
import { isLargeScreen } from "../utils/screenUtils";
import appTrackingService from "../services/appTracking.service";
import handleError, { isError } from "../utils/handleError";
import { getUserLogged, removeParamsFromUrl } from "../utils/loginUtils";
import { User } from "../types/storesTypes";
import SettingsService from "../services/settingService";

const Main = () => {
  const [logged, setLogged] = useState<boolean>(false);
  const [user, setUser] = React.useState<User | null>(null);
  const [appState, setAppState] = useState(appStore.default);
  const { width } = useLayout();

  useMountEffect(() => {
    const appStateSubscription = appStore.subscribe(setAppState);
    const [userLogged] = getUserLogged();
    if (userLogged) {
      removeParamsFromUrl();
      localStorage.setItem(LOCAL_STORAGE_LOGGED_NAME, String(userLogged));
      setLogged(userLogged);
    } else {
      getNam().subscribe((ret) => {
        if (IS_LOCAL() || ret.response == null) {
          const href = `/login.html?ENDPOINT=${API_ENDPOINT}`;
          window.location.href = href;
        } else {
          window.location.replace(ret.response);
        }
      });
    }
    return () => {
      appStateSubscription.unsubscribe();
    };
  });

  React.useEffect(() => {
    if (logged) {
      SettingsService.callGetUser().subscribe((res) => {
        if (isError(res)) {
          handleError(res);
        } else {
          appStore.initSettings(res);
          setUser(res);
          if (appStore.getAppInsights() === undefined) {
            const appInsights = appTrackingService.setAppTracking(res);
            appInsights && appStore.setAppInsights(appInsights);
          }
        }
      });
    }
  }, [logged]);

  if (appState.hasError) {
    return (
      <ErrorScreen
        errorMessage={appState.errorMessage || ""}
        msgDetail={appState.msgDetails || ""}
      />
    );
  }

  if (logged && user) {
    return (
      <View style={styles(width).container}>
        <VlProgressBar progress={appState.loadGauge} />
        <Navigation />
        <StatusBar />
      </View>
    );
  } else {
    return <AppLoading />;
  }
};

export default React.memo(Main);

const styles = (width: number) =>
  StyleSheet.create({
    container: {
      flex: 1,
      paddingLeft: isLargeScreen(width)
        ? LATERAL_DESKTOP_VIEW_PADDING_WIDTH
        : 0,
      paddingRight: isLargeScreen(width)
        ? LATERAL_DESKTOP_VIEW_PADDING_WIDTH
        : 0,
      backgroundColor: isLargeScreen(width) ? "black" : "none",
    },
  });
