import { VLabThemedColors } from "../types/appTypes";

const tintColorLight = "#2f95dc";
const tintColorDark = "#008bff";

export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
    filterText: "#000",
    filterLensesText: "#696969",
    filterBackground: "#ffffff",
    tableHeaderBackground: "#7ac9ff",
    timeBarBackground: "#d9d9d9",
    orderedText: "#1064A9",
    shippedText: "#000",
    chevron: "#1C1C1C",
    lensesFilterBackground: "#d6d6d6",
    lensesFilterSelectedBackground: "#1064A9",
    overviewTableRowBackground: "#fff",
    tableRowBackground: "#e8e8e8",
    tableBottomBorder: "#d9d9d9",
    settingsSectionBorder: "#1a1a1a",
    cardBodyBackgroundColor: "#f1f1f1",
    cardTitleBackgroundColor: "#d9d9d9",
    overviewTableFirstRow: "#1B2130",
    tableRowBgColPair: "#323232",
    tableRowBgCol: "#f1f1f1",
    splitHeaderBg: "#203E62",
    lensesTotalRow: "#1b2130",
    navigationHeaderColor: "#f1f1f1",
    cardRowBackgroundColor: "#f1f1f1",
    navigationHeaderSubtitle: "#1a1a1a",
    bottomNavigationDefaultColor: "#878787",
    bottomNavigationDefaultBackground: "#f1f1f1",
    bottomNavigationActiveBackground: "#d9d9d9",
    reportHeadRowBackground: "#d9d9d9",
    timespanFilterClickedBackground: "#3fc9f9",
    timebarText: "#050e24",
    dotsDays: "#004869",
    internalBottomNavigationDefaultBackground: "#003F7B",
    internalBottomTabInactiveBackgroundColor: "#00265E",
    infoLabLabelLeft: "#bababa",
    dividerSection: "#3D424E",
  },
  dark: {
    text: "#FFFFFF",
    background: "#050e24",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: "#3fc9f9",
    filterText: "#FFFFFF",
    filterLensesText: "#B7B7B7",
    filterBackground: "#3b4355",
    tableHeaderBackground: "#172C45",
    timeBarBackground: "#0491d1",
    orderedText: "#3fc9f9",
    shippedText: "#CFCFCF",
    chevron: "#878787",
    lensesFilterBackground: "#1C1C1C",
    lensesFilterSelectedBackground: "#1064A9",
    overviewTableRowBackground: "#272D3A",
    tableRowBackground: "#141414",
    tableBottomBorder: "#4D525D",
    settingsSectionBorder: "#676767",
    cardBodyBackgroundColor: "#000",
    cardTitleBackgroundColor: "#2C2C2C",
    overviewTableFirstRow: "#1A2130",
    tableRowBgColPair: "#272d3a",
    tableRowBgCol: "#272D3A",
    splitHeaderBg: "#203E62",
    lensesTotalRow: "#1b2130",
    navigationHeaderColor: "#050e24",
    cardRowBackgroundColor: "#1C1C1C",
    navigationHeaderSubtitle: "#A3A3A3",
    bottomNavigationDefaultColor: "#FFFFFF",
    bottomNavigationDefaultBackground: "#050e24",
    bottomNavigationActiveBackground: "#2D2D2D",
    reportHeadRowBackground: "#323232",
    timespanFilterClickedBackground: "#3fc9f9",
    timebarText: "#050e24",
    dotsDays: "#004869",
    internalBottomNavigationDefaultBackground: "#1e2e56",
    internalBottomTabInactiveBackgroundColor: "#161f34",
    infoLabLabelLeft: "#bababa",
    dividerSection: "#3D424E",
  },
} as VLabThemedColors;
