import React from "react";
import { VLabGlobalStore } from "../types/storesTypes";
import useMountEffect from "./useMountEffect";

const useGlobalState = <T>(store: VLabGlobalStore<T>): T | undefined => {
  const [state, setState] = React.useState<T | undefined>();
  useMountEffect(() => {
    const stateSubscription = store.subscribe(setState);
    return () => {
      stateSubscription.unsubscribe();
    };
  });
  return state;
};

export default useGlobalState;
