import React from "react";
import { ThemeContext } from "../context/ThemeContext";
import useScale, { Scales } from "../hooks/useScale";
import { VLabColors } from "../types/appTypes";
import { View, Text } from "./Themed";
import { StyleSheet } from "react-native";
import { FONT_SIZE_SETTINGS } from "../constants/mainStyles";

type CardRowProps = { title: string; value?: string | React.ReactNode };

const CardRow: React.FC<CardRowProps> = ({ title, value }) => {
  const scaleFunctions = useScale();
  const { colors } = React.useContext(ThemeContext);

  const valueElement =
    typeof value === "string" ? (
      <Text style={styles(colors, scaleFunctions).text}>{value}</Text>
    ) : (
      value
    );

  return (
    <View style={styles(colors, scaleFunctions).container}>
      <Text style={styles(colors, scaleFunctions).text}>{title}</Text>
      {valueElement}
    </View>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    container: {
      flexDirection: "row",
      flex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      minHeight: moderateScale(50, 0.2),
      paddingHorizontal: moderateScale(15, 0.2),
      borderBottomWidth: 1,
      borderBottomColor: colors.cardTitleBackgroundColor,
      backgroundColor: colors.cardRowBackgroundColor,
    },
    text: { fontSize: moderateScale(...FONT_SIZE_SETTINGS) },
  });

export default CardRow;
