import { Platform } from "react-native";

export const isLargeScreen = (width: number) =>
  Platform.OS === "web" && width >= 1600;

export const isMediumScreen = (width: number) =>
  Platform.OS === "web" && width > 1000 && width < 1600;

export const isSmallScreen = (width: number) =>
  Platform.OS === "web" && width >= 500 && width <= 1000;

export const calculateWidthChart = (width: number) => {
  if (isLargeScreen(width)) {
    return width / 2;
  } else if (isMediumScreen(width)) {
    return width - 300;
  } else if (isSmallScreen(width)) {
    return width - 100;
  }
  return width + 100;
};
