import { catchError, map } from "rxjs/operators";
import { /*Observable,*/ of } from "rxjs";
import handleError from "../utils/handleError";
import { getInfo, getUser, setPreferences } from "../apis/SettingsApi";
import { Preferences } from "../types/apiTypes";
// import { AjaxError } from "rxjs/ajax";
class SettingsService {
  callInfo = () => {
    return getInfo().pipe(
      map((res) => {
        return { ...res.response };
      }),
      catchError((err) => {
        handleError(err);
        return of(err);
      })
    );
  };

  callSetPreferences = (preferences: Preferences) => {
    return setPreferences(preferences).pipe(
      catchError((err) => {
        handleError(err);
        return of(err);
      })
    );
  };

  callGetUser = () => {
    return getUser().pipe(
      map((res) => {
        return {
          ...res.response,
        };
      }),
      catchError((err) => {
        handleError(err);
        return of(err);
      })
    );
  };
}

export default new SettingsService();
