import * as React from "react";
import {
  Table,
  TableWrapper,
  Cell,
  Row,
} from "@deb-95/react-native-table-component";
import {
  VIEW_STYLES,
  TABLE_FONT_SIZE,
  TABLE_TITLE_FONT_SIZE,
  TABLE_UNIT_MEASUREMENT_SIZE,
  LENSES_TABLE_ROW_MAX_HEIGHT,
  LENSES_TABLE_ROW_MIN_HEIGHT,
  BASE_FONT,
  LATO_BASE_FONT,
  LENSES_TABLE_HEAD_HEIGHT,
  TABLE_HEAD_PADDING,
} from "../../constants/mainStyles";
import {
  CELL_PADDING_RIGHT,
  TABLE_HEADER_ELEMENTS,
  LAST_CELL_PADDING_RIGHT,
  BRAZIL_LAB_CODE,
  JAPAN_LAB_CODE,
} from "../../constants/appBaseConstants";
import { VLabColors } from "../../types/appTypes";
import { LensesSplit, LensesTableData, LensItem } from "../../types/apiTypes";
import { View, ScrollView, StyleSheet, ViewStyle } from "react-native";
import { AppState } from "../../types/storesTypes";
import { ThemeContext } from "../../context/ThemeContext";
import {
  formatNumber,
  formatNumberKpiServiceBrazilJapan,
  numberWithUnit,
  formatNumberWithRound,
} from "../../utils/formatNumber";
import useScale, { Scales } from "../../hooks/useScale";
import {
  TABLE_HEADER_EMPTY,
  TABLE_HEADER_SERVICE,
} from "../../constants/dictionary";
import { TableText } from "../TableText";
import { brightenUp } from "../../utils/colorsUtils";

const flexArrHeader = [2, 1.5, 1.5, 1, 1];
const flexArrSplitHeader = [2, 1.5, 1.5, 1, 1];
const flexArr = [2, 1.5, 1.5, 1, 1];

type LensesTableProps = {
  lensesData: LensesTableData;
  appState: AppState;
};

const LensesTable = ({ lensesData, appState }: LensesTableProps) => {
  const { colors } = React.useContext(ThemeContext);
  const tableHead = lensesData?.head;
  const scale = useScale();
  /*[VLP-349] [VLP-348] label changed from '-' to 'n.a.' when kpi service is null for Brazil and Japan lab only*/
  const tableHeadServiceKpi =
    tableHead?.meta?.id === BRAZIL_LAB_CODE ||
    tableHead?.meta?.id === JAPAN_LAB_CODE
      ? formatNumberKpiServiceBrazilJapan(
          tableHead.service,
          appState.selectedLanguage
        )
      : formatNumber(tableHead?.service, appState.selectedLanguage);
  const headData = [
    tableHead?.meta.labels[appState.selectedLanguage],
    formatNumber(tableHead?.orders, appState.selectedLanguage),
    formatNumber(tableHead?.shipments, appState.selectedLanguage),
    tableHeadServiceKpi,
    numberWithUnit(
      formatNumber(tableHead?.quality, appState.selectedLanguage),
      "%"
    ),
  ];

  const getTableHead = () => [
    <TableText
      key={`headNull`}
      alignment={"right"}
      bgCol={colors.tableRowBgColPair}
      additionalStyle={{
        paddingRight: scale.moderateScale(CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {TABLE_HEADER_EMPTY[appState.selectedLanguage]}
    </TableText>,

    ...TABLE_HEADER_ELEMENTS(appState.selectedLanguage).map(
      (lensesHeaderElement: [string, string], index: number) => (
        <View
          key={`head${index}`}
          style={styles(colors, scale).headTextContainer}
        >
          <TableText
            color={index === 0 ? colors.orderedText : colors.text}
            alignment={"right"}
            justifyContent={"flex-start"}
            bgCol={
              index % 2 !== 1
                ? brightenUp(colors.tableRowBgColPair)
                : colors.tableRowBgColPair
            }
            additionalStyle={[
              index === 3
                ? styles(colors, scale).lastCellPaddingRx
                : styles(colors, scale).cellPaddingRx,
              styles(colors, scale).headTextMarginTop,
            ]}
          >
            {`${lensesHeaderElement[0]
              .charAt(0)
              .toUpperCase()}${lensesHeaderElement[0].slice(1)}`}
            {"\n"}
            {lensesHeaderElement[1] !==
              TABLE_HEADER_SERVICE[appState.selectedLanguage] && (
              <span style={{ fontSize: scale.moderateScale(9, 0.2) }}>
                ({lensesHeaderElement[1]})
              </span>
            )}
          </TableText>
          {lensesHeaderElement[1] ===
            TABLE_HEADER_SERVICE[appState.selectedLanguage] && (
            <TableText
              color={index === 0 ? colors.orderedText : colors.text}
              alignment={"right"}
              justifyContent={"flex-start"}
              bgCol={
                index % 2 !== 1
                  ? brightenUp(colors.tableRowBgColPair)
                  : colors.tableRowBgColPair
              }
              additionalStyle={[
                styles(colors, scale).unitMeasurementFontSize,
                index === 3
                  ? styles(colors, scale).lastCellPaddingRx
                  : styles(colors, scale).cellPaddingRx,
                styles(colors, scale).headTextMarginBottom,
              ]}
            >
              {`(${lensesHeaderElement[1]})`}
            </TableText>
          )}
        </View>
      )
    ),
    TABLE_HEADER_EMPTY[appState.selectedLanguage],
  ];

  const renderTableFirstRow = () => (
    <TableWrapper
      style={[
        VIEW_STYLES.row,
        styles(colors, scale).row,
        { backgroundColor: colors.lensesTotalRow },
        styles(colors, scale).tableHorizontalBorder,
      ]}
    >
      {headData.map((headVal, index) => (
        <Cell
          key={"tableHeadCell" + index}
          data={
            <TableText
              color={colors.text}
              bgCol={
                index % 2 !== 1
                  ? colors.lensesTotalRow
                  : brightenUp(colors.lensesTotalRow)
              }
              alignment={index === 0 ? "left" : "right"}
              numberOfLines={2}
              ellipsizeMode="clip"
              fontFam={index !== 0 ? LATO_BASE_FONT : BASE_FONT}
              additionalStyle={
                index === 4
                  ? styles(colors, scale).lastCellPaddingRx
                  : styles(colors, scale).cellPaddingRx
              }
            >
              {headVal}
            </TableText>
          }
          style={[
            index === 0
              ? styles(colors, scale).firstCellAlignment
              : styles(colors, scale).cellAlignment,
            { flex: flexArr[index] },
          ]}
        />
      ))}
    </TableWrapper>
  );

  const getSplitItems = (splitItem: LensItem) => [
    <TableText
      key={`TableText1`}
      color={colors.text}
      bgCol={colors.tableRowBgCol}
      alignment={"left"}
      additionalStyle={{
        paddingRight: scale.moderateScale(CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {splitItem.meta.labels[appState.selectedLanguage]}
    </TableText>,

    <TableText
      key={`TableText2`}
      color={colors.text}
      bgCol={brightenUp(colors.tableRowBgCol)}
      fontFam={LATO_BASE_FONT}
      alignment={"right"}
      additionalStyle={{
        paddingRight: scale.moderateScale(CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {formatNumber(splitItem.orders, appState.selectedLanguage)}
    </TableText>,

    <TableText
      key={`TableText3`}
      color={colors.text}
      bgCol={colors.tableRowBgCol}
      fontFam={LATO_BASE_FONT}
      alignment={"right"}
      additionalStyle={{
        paddingRight: scale.moderateScale(CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {formatNumber(splitItem.shipments, appState.selectedLanguage)}
    </TableText>,

    <TableText
      key={`TableText4`}
      color={colors.text}
      bgCol={brightenUp(colors.tableRowBgCol)}
      fontFam={LATO_BASE_FONT}
      alignment={"right"}
      additionalStyle={{
        paddingRight: scale.moderateScale(CELL_PADDING_RIGHT, 0.2),
      }}
    >
      {/*[VLP-349] label changed from '-' to 'n.a.' when kpi service is null for brazil lab only*/}
      {tableHead.meta.id === BRAZIL_LAB_CODE ||
      tableHead.meta.id === JAPAN_LAB_CODE
        ? formatNumberKpiServiceBrazilJapan(
            splitItem.service,
            appState.selectedLanguage
          )
        : formatNumberWithRound(splitItem.service, appState.selectedLanguage)}
    </TableText>,

    <TableText
      key={`TableText5`}
      color={colors.text}
      bgCol={colors.tableRowBgCol}
      fontFam={LATO_BASE_FONT}
      alignment={"right"}
      additionalStyle={{
        paddingRight: scale.moderateScale(10, 0.2),
      }}
    >
      {numberWithUnit(
        formatNumberWithRound(splitItem.quality, appState.selectedLanguage),
        "%"
      )}
    </TableText>,
  ];

  const renderSplitItems = (splitItem: LensItem, index: number) => (
    <Row
      key={`splitItem${index}`}
      data={getSplitItems(splitItem)}
      flexArr={flexArr}
      style={[
        VIEW_STYLES.row,
        styles(colors, scale).row,
        styles(colors, scale).tableHorizontalBorder,
      ]}
    />
  );

  const getSplitHeader = (
    splitText: string,
    bgColPair = brightenUp(colors.splitHeaderBg),
    bgColOdd = colors.splitHeaderBg
  ) => [
    <TableText color={colors.text} bgCol={bgColOdd} key={`splitHeader0`}>
      {splitText}
    </TableText>,
    <TableText color={colors.text} bgCol={bgColPair} key={`splitHeader1`}>
      {" "}
    </TableText>,
    <TableText color={colors.text} bgCol={bgColOdd} key={`splitHeader2`}>
      {" "}
    </TableText>,
    <TableText color={colors.text} bgCol={bgColPair} key={`splitHeader3`}>
      {" "}
    </TableText>,
    <TableText color={colors.text} bgCol={bgColOdd} key={`splitHeader4`}>
      {" "}
    </TableText>,
  ];

  const renderSplitSection = (split: LensesSplit, index: number) => {
    const splitText = split.splitMeta.labels[appState.selectedLanguage];
    return (
      <React.Fragment key={`split${index}`}>
        <Row
          data={getSplitHeader(splitText)}
          style={[
            styles(colors, scale).sectionTitleContainer,
            styles(colors, scale).tableRowBottomBorder,
          ]}
          textStyle={styles(colors, scale).sectionTitleText}
          flexArr={flexArrSplitHeader}
        />
        {split.items.map(renderSplitItems)}
      </React.Fragment>
    );
  };

  return (
    <View
      style={[
        styles(colors, scale).container,
        { backgroundColor: colors.overviewTableRowBackground },
      ]}
    >
      <Table
        style={[
          styles(colors, scale).container,
          { flex: 3, backgroundColor: colors.overviewTableRowBackground },
        ]}
      >
        <Row
          data={getTableHead()}
          flexArr={flexArrHeader}
          style={[
            VIEW_STYLES.row,
            styles(colors, scale).tableHeaderContainer,
            { backgroundColor: colors.overviewTableRowBackground },
            styles(colors, scale).tableRowBottomBorder,
          ]}
        />
        {renderTableFirstRow()}
        <TableWrapper style={styles(colors, scale).container}>
          <ScrollView
            contentContainerStyle={{ flex: 1 }}
            showsVerticalScrollIndicator={false}
          >
            {lensesData?.splits.map(renderSplitSection)}
          </ScrollView>
        </TableWrapper>
      </Table>
    </View>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) => {
  const LENSES_TABLE_ROW_STYLE: ViewStyle = {
    minHeight: moderateScale(...LENSES_TABLE_ROW_MIN_HEIGHT),
    maxHeight: moderateScale(...LENSES_TABLE_ROW_MAX_HEIGHT),
  };
  const LENSES_TABLE_HEAD_STYLE: ViewStyle = {
    minHeight: moderateScale(...LENSES_TABLE_HEAD_HEIGHT),
    maxHeight: moderateScale(...LENSES_TABLE_HEAD_HEIGHT),
  };
  const TABLE_ROW_BOTTOM_BORDER: ViewStyle = {
    borderBottomColor: colors.tableBottomBorder,
    borderBottomWidth: 1,
  };
  const TABLE_HORIZONTAL_BORDER: ViewStyle = {
    paddingLeft: moderateScale(16, 0.2),
    // paddingRight: moderateScale(5, 0.2)
  };
  return StyleSheet.create({
    container: {
      flex: 1,
    },
    text: {
      color: colors.text,
      fontFamily: BASE_FONT,
      fontSize: moderateScale(...TABLE_FONT_SIZE),
    },
    cellAlignment: {
      alignItems: "flex-end",
    },
    firstCellAlignment: {
      alignItems: "flex-start",
    },
    tableHeaderContainer: {
      flex: 1,
      backgroundColor: colors.tableHeaderBackground,
      ...LENSES_TABLE_HEAD_STYLE,
      ...TABLE_HORIZONTAL_BORDER,
    },
    headTextContainer: {
      flex: 1,
      flexDirection: "column",
    },
    row: {
      flex: 1,
      ...LENSES_TABLE_ROW_STYLE,
      ...TABLE_ROW_BOTTOM_BORDER,
      backgroundColor: colors.tableRowBgCol,
    },
    sectionTitleContainer: {
      backgroundColor: colors.splitHeaderBg,
      ...LENSES_TABLE_ROW_STYLE,
      ...TABLE_HORIZONTAL_BORDER,
    },
    sectionTitleText: {
      color: colors.text,
      fontSize: moderateScale(...TABLE_TITLE_FONT_SIZE),
    },
    tableRowBottomBorder: { ...TABLE_ROW_BOTTOM_BORDER },
    tableHorizontalBorder: { ...TABLE_HORIZONTAL_BORDER },
    cellPaddingRx: {
      paddingRight: moderateScale(CELL_PADDING_RIGHT, 0.2),
    },
    lastCellPaddingRx: {
      paddingRight: moderateScale(LAST_CELL_PADDING_RIGHT, 0.2),
    },
    unitMeasurementFontSize: {
      fontSize: moderateScale(...TABLE_UNIT_MEASUREMENT_SIZE),
    },
    headTextMarginTop: {
      marginTop: moderateScale(...TABLE_HEAD_PADDING),
    },
    headTextMarginBottom: {
      marginBottom: moderateScale(...TABLE_HEAD_PADDING),
    },
  });
};
export default LensesTable;
