import { StackHeaderTitleProps } from "@react-navigation/stack";
import React, { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { MEDIUM_FONT } from "../constants/mainStyles";
import { ThemeContext } from "../context/ThemeContext";
import useScale, { Scales } from "../hooks/useScale";
import { VLabColors } from "../types/appTypes";
import { Text } from "./Themed";

type HeaderTitleComponentProps = {
  title: string;
  subtitle?: string;
};

const HeaderTitleComponent = (
  props: StackHeaderTitleProps & HeaderTitleComponentProps
) => {
  const { colors } = useContext(ThemeContext);
  const scale = useScale();
  const { title, subtitle } = props;
  return (
    <View style={[props.style, styles(colors, scale).headerContainer]}>
      <Text style={styles(colors, scale).headerTitleText}>{title}</Text>
      {subtitle && (
        <Text style={styles(colors, scale).headerSubtitleText}>{subtitle}</Text>
      )}
    </View>
  );
};

const styles = (colors: VLabColors, { moderateScale }: Scales) =>
  StyleSheet.create({
    headerContainer: {
      flex: 1,
      flexDirection: "column",
      alignContent: "center",
    },
    headerTitleText: {
      fontFamily: MEDIUM_FONT,
      textTransform: "capitalize",
      textAlign: "center",
      fontSize: moderateScale(18, 0.2),
      fontWeight: "bold",
    },
    headerSubtitleText: {
      fontFamily: MEDIUM_FONT,
      textAlign: "center",
      color: "white",
      fontSize: moderateScale(14, 0.2),
      paddingTop: 3,
    },
  });

export default HeaderTitleComponent;
