import { ALL_HEADER_TITLE, BY_HEADER } from "./../constants/dictionary";
import {
  LABORATORIES,
  LENSES_TITLE,
  OVERVIEW_SCREEN_LABEL,
} from "../constants/dictionary";
import { LanguagesEnum } from "../types/appEnums";
import { LabeledObject } from "../types/appTypes";
import { LAB_LABEL } from "../constants/appBaseConstants";

export const getOverviewTitle = (
  selectedLab: LabeledObject,
  selectedLanguage: LanguagesEnum,
  isChannelGroup: boolean
) => {
  const title = `${getLabName(selectedLab, selectedLanguage, isChannelGroup)}`;
  const subtitle = OVERVIEW_SCREEN_LABEL[selectedLanguage];
  return { title, subtitle };
};

export const getLensesTitle = (
  selectedChannel: LabeledObject,
  selectedLab: LabeledObject,
  selectedLanguage: LanguagesEnum,
  isChannelGroup: boolean
) => {
  const isLeaf = selectedChannel.id !== "ALL";
  if (isLeaf) {
    return {
      title: `${selectedChannel.labels[selectedLanguage]}`,
      subtitle: `${BY_HEADER[selectedLanguage]} ${selectedLab.labels[selectedLanguage]}`,
    };
  } else {
    return {
      title: `${getLabName(selectedLab, selectedLanguage, isChannelGroup)}`,
      subtitle: LENSES_TITLE[selectedLanguage],
    };
  }
};

export const getInfoLabTitle = (
  selectedLab: LabeledObject,
  selectedLanguage: LanguagesEnum,
  isChannelGroup: boolean
) => {
  const title = `${getLabName(selectedLab, selectedLanguage, isChannelGroup)}`;
  return { title };
};

const getLabName = (
  selectedLab: LabeledObject,
  selectedLanguage: LanguagesEnum,
  isChannelGroup: boolean
) => {
  const labText = isChannelGroup
    ? ` ${LAB_LABEL}`
    : ` ${LABORATORIES[selectedLanguage]}`;
  const labTitle =
    selectedLab.id === "ALL"
      ? ALL_HEADER_TITLE[selectedLanguage]
      : selectedLab.labels[selectedLanguage];
  return `${labTitle}${labText}`;
};
